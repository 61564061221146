import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';
import {decryptToken} from '../../../utils/tokenValidation'; 

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #038ea1;
  color: white;
  padding-top: 40px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 5%;
`;

const MainHeading = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #fff;
  margin-left: 2%;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #a76ef2;
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  max-width: 150px;

  &:hover {
    background-color: #8a5bc9;
  }
`;

const InputFieldContainer = styled.div`
  margin-bottom: 15px;
`;

const InputField = ({ name, placeholder, type = 'text', value, onChange, required = false }) => (
  <InputFieldContainer>
    <input
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      required={required}
      style={{
        padding: '10px',
        borderRadius: '0px',
        border: 'none',
        maxWidth: '350px',
        width: '100%',
      }}
    />
  </InputFieldContainer>
);

const CreateUserForm = ({ formData, handleInputChange, handleSubmit, errors }) => (
  <form onSubmit={handleSubmit}>
    <InputField name="username" placeholder="Username" value={formData.username} onChange={handleInputChange} required />
    {errors.username && <span style={{ color: 'red' }}>{errors.username}</span>}
    
    <InputField name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
    {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
    
    <InputField name="password" placeholder="Password" type="password" value={formData.password} onChange={handleInputChange} required />
    {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
    
    <InputField name="first_name" placeholder="First Name" value={formData.first_name} onChange={handleInputChange} required />
    {errors.first_name && <span style={{ color: 'red' }}>{errors.first_name}</span>}
    
    <InputField name="last_name" placeholder="Last Name" value={formData.last_name} onChange={handleInputChange} required />
    {errors.last_name && <span style={{ color: 'red' }}>{errors.last_name}</span>}
    
    <InputField name="date_of_birth" placeholder="Date of Birth (YYYY-MM-DD)" value={formData.date_of_birth} onChange={handleInputChange} required />
    {errors.date_of_birth && <span style={{ color: 'red' }}>{errors.date_of_birth}</span>}
    
    <InputField name="phone_number" placeholder="Phone Number" value={formData.phone_number} onChange={handleInputChange} required />
    {errors.phone_number && <span style={{ color: 'red' }}>{errors.phone_number}</span>}
    
    <InputField name="street_address" placeholder="Street Address" value={formData.street_address} onChange={handleInputChange} required />
    {errors.street_address && <span style={{ color: 'red' }}>{errors.street_address}</span>}
    
    <InputField name="city" placeholder="City" value={formData.city} onChange={handleInputChange} required />
    {errors.city && <span style={{ color: 'red' }}>{errors.city}</span>}
    
    <InputField name="state" placeholder="State" value={formData.state} onChange={handleInputChange} required />
    {errors.state && <span style={{ color: 'red' }}>{errors.state}</span>}
    
    <InputField name="zip_code" placeholder="Zip Code" value={formData.zip_code} onChange={handleInputChange} required />
    {errors.zip_code && <span style={{ color: 'red' }}>{errors.zip_code}</span>}
    
    <InputField name="affiliate_id" placeholder="Affiliate ID (optional)" value={formData.affiliate_id} onChange={handleInputChange} />
    
    <SubmitButton type="submit">Submit</SubmitButton>
  </form>
);

const Content = ({ selectedMenu }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    phone_number: '',
    street_address: '',
    city: '',
    state: '',
    zip_code: '',
    affiliate_id: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error messages for the specific input being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const sessionToken = decryptToken();
      const headers = {
        'Authorization': `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        `${ROOT_URL}admin/create-user`,
        formData,
        { headers }
      );

      console.log('User created successfully:', response.data);
      setSuccessMessage('User created successfully!');
      // Reset form data after successful submission
      setFormData({
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone_number: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: '',
        affiliate_id: '',
      });
    } catch (error) {
      console.error('Error creating user:', error.response ? error.response.data : error.message);
      if (error.response && error.response.data.detail) {
        const apiErrors = error.response.data.detail.reduce((acc, curr) => {
          acc[curr.loc[1]] = curr.msg;
          return acc;
        }, {});
        setErrors(apiErrors);
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <ContentContainer>
      {selectedMenu === 'Create User' && (
        <FormContainer>
          <MainHeading>CREATE USER</MainHeading>
          {successMessage && <div style={{ color: '#fff' }}>{successMessage}</div>}
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          <CreateUserForm formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} errors={errors} />
        </FormContainer>
      )}
      {/* Add more forms or content for other menus */}
    </ContentContainer>
  );
};

export default Content;
