import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || 'NowYouCannotCrackiT';

// Encrypt the token before storing it
export const encryptToken = (token) => {
    if (!SECRET_KEY) {
        throw new Error('SECRET_KEY is not defined in the environment');
    }

    const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
    localStorage.setItem('authToken', encryptedToken);
};

// Decrypt the token before using it
export const decryptToken = () => {
    const encryptedToken = localStorage.getItem('authToken');
    if (encryptedToken && SECRET_KEY) {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
        const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedToken;
    }
    return null; // Return null if there's no token or no secret key
};
