import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ROOT_URL } from '../../../utils/variable';
import { decryptToken } from '../../../utils/tokenValidation';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #024e68;
  padding: 20px;
  max-width: 750px;
  max-height: 75vh;
  overflow-y: scroll;
  border-radius: 8px;
  color: white;
  width: 100%;
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
  color:#fff;
  text-align:center;
`;

const TransactionList = styled.div`
  margin-top: 20px;
`;

const TransactionItem = styled.div`
  background-color: #03607f;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007b8f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #005f6b;
  }
`;

const UserTransactionHistory = ({ user, closeModal }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (user && user.id) {
      const fetchTransactionHistory = async () => {
        try {
          const sessionToken = decryptToken();
          const headers = {
            'Authorization': `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          };

          const response = await axios.get(
            `${ROOT_URL}admin/user/${user.id}/topup-history`,
            { headers }
          );

          if (response.data.transactions) {
            setTransactions(response.data.transactions);
          }
        } catch (error) {
          console.error('Error fetching transaction history:', error);
        }
      };

      fetchTransactionHistory();
    }
  }, [user]);

  if (!user || !user.id) {
    return null;
  }

  return (
    <ModalBackground>
      <ModalContent>
        <ModalHeader>Transaction History</ModalHeader>

        {/* Display Transaction History */}
        <TransactionList>
          <h3>Recent Transactions</h3>
          {transactions.length === 0 ? (
            <p>No transactions found.</p>
          ) : (
            transactions.map((transaction) => (
              <TransactionItem key={transaction.id}>
                <p><strong>Invoice:</strong> {transaction.invoice}</p>
                <p><strong>Amount:</strong> {transaction.amount} {transaction.currency_type}</p>
                <p><strong>Status:</strong> {transaction.status}</p>
                <p><strong>Created At:</strong> {new Date(transaction.created_at).toLocaleString()}</p>
              </TransactionItem>
            ))
          )}
        </TransactionList>

        <Button onClick={closeModal}>Close</Button>
      </ModalContent>
    </ModalBackground>
  );
};

export default UserTransactionHistory;
