import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';
import { decryptToken } from '../../../utils/tokenValidation';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #024e68;
  padding: 20px;
  width: 675px;
  border-radius: 8px;
  color: white;
  overflow-y: auto; /* Prevent overflow in case of a lot of data */
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
  color: #fff;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.thead`
  background-color: #033d4e;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  font-size: 16px;
  color: white;
`;

const TableDataCell = styled.td`
  padding: 10px;
  text-align: left;
  font-size: 14px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007b8f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #005f6b;
  }
`;

const CancelButton = styled(Button)`
  background-color: #555;

  &:hover {
    background-color: #333;
  }
`;

const AffiliateStatusModal = ({ affiliate, closeModal }) => {
  const [affiliateStats, setAffiliateStats] = useState(null); // To store fetched affiliate stats
  const [loading, setLoading] = useState(true); // To track if data is still loading

  // Fetch affiliate stats when modal is opened
  useEffect(() => {
    const fetchAffiliateStats = async () => {
      try {
        const sessionToken = decryptToken();
        const headers = {
          'Authorization': `Bearer ${sessionToken}`,
        };

        const response = await axios.get(
          `${ROOT_URL}api/affiliate/stats?affiliate_id=${affiliate.affiliate_id}`,
          { headers }
        );

        if (response.status === 200) {
          setAffiliateStats(response.data); // Store the fetched stats
        }
      } catch (error) {
        console.error('Error fetching affiliate stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliateStats();
  }, [affiliate.affiliate_id]);

  // If data is still loading, show a loading screen
  if (loading) {
    return (
      <ModalBackground>
        <ModalContent>
          <ModalHeader>Loading Affiliate Data...</ModalHeader>
        </ModalContent>
      </ModalBackground>
    );
  }

  return (
    <ModalBackground>
      <ModalContent>
        <ModalHeader>Affiliate Information</ModalHeader>
        {affiliateStats && (
          <>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Field</TableHeaderCell>
                  <TableHeaderCell>Value</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <tbody>
                <TableRow>
                  <TableDataCell><strong>Name:</strong></TableDataCell>
                  <TableDataCell>{affiliateStats.name}</TableDataCell>
                </TableRow>
                <TableRow>
                  <TableDataCell><strong>Player Count:</strong></TableDataCell>
                  <TableDataCell>{affiliateStats.player_count}</TableDataCell>
                </TableRow>
                <TableRow>
                  <TableDataCell><strong>Commission Percentage:</strong></TableDataCell>
                  <TableDataCell>{affiliateStats.commission_percentage}%</TableDataCell>
                </TableRow>
                <TableRow>
                  <TableDataCell><strong>Estimated Commission MTD:</strong></TableDataCell>
                  <TableDataCell>${affiliateStats.estimated_commission_mtd}</TableDataCell>
                </TableRow>
              </tbody>
            </Table>

            <ModalActions>
              <CancelButton onClick={closeModal}>Close</CancelButton>
            </ModalActions>
          </>
        )}
      </ModalContent>
    </ModalBackground>
  );
};

export default AffiliateStatusModal;
