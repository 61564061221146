import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';
import '../GameGrid.css';
import { decryptToken } from '../../../utils/tokenValidation';
const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#690DAC',
    color: 'white',
    padding: '20px',
    borderRadius: '48px',
    border: '12px solid #F7CEB2',
    maxWidth: '650px',
    //boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 15px rgba(0, 0, 0, 0.3)', // Stacked inset shadows
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3), 0 0 40px rgba(255, 204, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  svgContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    transform: 'translateY(-40px)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '32px',
    fontWeight: '700',
    color: '#000',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
  content: {
    overflowY: 'auto', 
    maxHeight: '70vh', 
    paddingRight: '10px', 
  },
  inputField: {
    width: '100%',
    padding: '10px',
    borderRadius: '18px',
    color: '#6A0DAD',
    border: 'none',
    marginBottom: '4px',
    fontSize: '16px',
    fontWeight: '500',
    backgroundColor: '#fff',
  },
  button: {
    marginTop: '4px',
    padding: '10px 24px',
    border: 'none',
    borderRadius: '18px',
    cursor: 'pointer',
    backgroundColor: 'white',
    color: '#6A0DAD',
    transition: 'background-color 0.3s',
    marginBottom: '12px',
  },
  mainHeading: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bolder',
    marginBottom: '15px',
    textAlign:'center'
  },
  table: {
    width: '100%',
    marginTop: '10px',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#5a0a9c',
    color: 'white',
  },
  tableCell: {
    padding: '8px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  },
  cancelBtn: {
    backgroundColor: 'white',
    color: '#6A0DAD',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  noPendingMessage: {
    color: '#fff',
    marginTop: '10px',
    fontSize:'16px'
  },
  closeButton: {
    backgroundColor: 'transparent',
    color: 'white',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    marginBottom: '15px', // Add margin for spacing
    textAlign:'right'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center the logout button
    marginBottom: '10px',
  },
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center text inside button
    padding: '6px 10px',
    backgroundColor: '#3A1BD8',
    border: '6px solid #F7CEB2',
    borderRadius: '36px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '32px',
    fontWeight: '800',
    color: '#fff',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
};

const GameHistoryModal = ({ isOpen, onClose }) => {
  const [gameHistory, setGameHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;


  useEffect(() => {
    const fetchGameHistory = async () => {
      if (isOpen) {
        setIsLoading(true);
        const sessionToken = decryptToken();
        const userId = localStorage.getItem('user_id');
        try {
          const response = await axios.get(`${ROOT_URL}game/game-history`, {
            params: {
              user_id: userId,
              page: currentPage,
              page_size: pageSize,
            },
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          });
          console.log('Response of game history:',response.data);
          setGameHistory(response.data.data);
          setTotalRecords(response.data.total_records);
          setTotalPages(response.data.total_pages);
        } catch (err) {
          console.error('Failed to fetch game history', err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchGameHistory();
  }, [isOpen, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        <div style={modalStyles.svgContainer}>
          <svg width="350" height="" viewBox="0 0 716 173" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M714.86 25.035C713.37 24.945 711.12 24.895 708.42 25.155C704.32 25.555 698.96 26.075 695.78 29.055C692.17 32.435 692.35 38.065 692.4 40.175C692.82 57.935 712.92 172.505 621.36 172.505C529.8 172.505 66.76 172.505 66.76 172.505C66.76 172.505 19.02 168.585 17.06 119.535V40.155C17.04 39.515 16.65 31.155 9.70001 27.015C5.61001 24.585 1.48001 24.935 0.0100098 25.135C0.28001 23.115 1.79 20.885 10.56 13.955C18.52 7.66502 27.21 7.76502 29.48 7.86502H695.26C695.26 7.86502 716.7 0.295017 714.86 25.035Z" fill="#EA8500" />
            <path d="M715.1 24.605C713.61 24.515 711.36 24.465 708.66 24.725C704.56 25.125 699.2 25.645 696.02 28.625C692.41 32.005 692.59 37.635 692.64 39.745C693.06 57.505 712.91 164.665 621.35 164.665C529.79 164.665 66.75 164.665 66.75 164.665C66.75 164.665 19.01 160.745 17.05 111.695V39.755C17.03 39.115 16.64 30.755 9.69 26.615C5.6 24.185 1.47 24.535 0 24.735C0.27 22.715 1.77999 13.055 10.55 6.11502C18.51 -0.174977 27.2 -0.0749806 29.47 0.0250194H695.25C695.26 0.0150194 716.94 -0.144979 715.1 24.605Z" fill="url(#paint0_linear_23_6)" />
            <defs>
              <linearGradient id="paint0_linear_23_6" x1="357.882" y1="-1.17058" x2="356.193" y2="150.842" gradientUnits="userSpaceOnUse">
                <stop offset="1.38522e-07" stop-color="#EBAE27" />
                <stop offset="0.0855" stop-color="#F9B815" />
                <stop offset="0.1814" stop-color="#FBEA28" />
                <stop offset="0.519" stop-color="#FDD132" />
                <stop offset="1" stop-color="#FEC833" />
              </linearGradient>
            </defs>
          </svg>
          <h2 style={modalStyles.title}>GAME HISTORY</h2>
        </div>
        {isLoading && <div>Loading...</div>}
        <div style={modalStyles.content}>
        {gameHistory.length > 0 ? (
          <div>
            <table style={modalStyles.table}>
              <thead style={modalStyles.tableHeader}>
                <tr>
                  <th style={modalStyles.tableCell}>Date</th>
                  <th style={modalStyles.tableCell}>Game Title</th>
                  <th style={modalStyles.tableCell}>Action</th>
                  <th style={modalStyles.tableCell}>Amount</th>
                  <th style={modalStyles.tableCell}>Balance Before</th>
                  <th style={modalStyles.tableCell}>Balance After</th>
                  <th style={modalStyles.tableCell}>Status</th>
                </tr>
              </thead>
              <tbody>
                {gameHistory.map((game) => (
                  <tr key={game.created_at}>
                    <td style={modalStyles.tableCell}>{new Date(game.created_at).toLocaleString()}</td>
                    <td style={modalStyles.tableCell}>{game.game_title}</td>
                    <td style={modalStyles.tableCell}>{game.action}</td>
                    <td style={modalStyles.tableCell}>{game.amount}</td>
                    <td style={modalStyles.tableCell}>{game.balance_before}</td>
                    <td style={modalStyles.tableCell}>{game.balance_after}</td>
                    <td style={modalStyles.tableCell}>{game.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
              <span> Page {currentPage} of {totalPages} </span>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
          </div>
        ) : (
          <div style={modalStyles.noPendingMessage}>NO GAME HISTORY AVAILABLE</div>
        )}
         <div style={modalStyles.row}>
            <button style={modalStyles.logoutButton} onClick={onClose}>
              <h2>
                CLOSE
              </h2>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameHistoryModal;