export const KYCpolicy = () => (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <link
        rel="shortcut icon"
        href="assets/images/fav.png"
        type="image/x-icon"
      />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/jquery-ui.css" />
      <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
      <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
      <link rel="stylesheet" href="assets/css/plugin/slick.css" />
      <link rel="stylesheet" href="assets/css/ara-font.css" />
      <link rel="stylesheet" href="assets/css/plugin/animate.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      
      {/* Scroll To Top Start*/}
      <a href="javascript:void(0)" className="scrollToTop">
        <i className="fas fa-angle-double-up" />
      </a>
      {/* Scroll To Top End */}
   {/* header-section start */}
   <header className="header-section">
            <div className="overlay">
                <div className="container">
                    <div className="row d-flex header-area">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="/home">
                                <img
                                    src="assets/images/fav.png"
                                    className="fav d-none d-lg-block d-xl-none"
                                    alt="fav"
                                />
                                <img
                                    src="assets/images/logo.png"
                                    className="logo d-block d-lg-none d-xl-block"
                                    alt="logo"
                                />
                            </a>
                            <button
                                className="navbar-toggler collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbar-content"
                            >
                                <i className="fas fa-bars" />
                            </button>
                            <div
                                className="collapse navbar-collapse justify-content-between"
                                id="navbar-content"
                            >
                                <div className="right-area header-action d-flex align-items-center max-un">
                                    <button
                                        type="button"
                                        className="login"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign In
                                    </button>
                                    <button
                                        type="button"
                                        className="cmn-btn reg"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        {/* header-section end */}
      {/* Login Registration start */}
      <div className="log-reg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="loginMod">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ul className="nav log-reg-btn d-none justify-content-around">
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link"
                          id="regArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#regArea"
                          type="button"
                          role="tab"
                          aria-controls="regArea"
                          aria-selected="false"
                        >
                          SIGN UP
                        </button>
                      </li>
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link active"
                          id="loginArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#loginArea"
                          type="button"
                          role="tab"
                          aria-controls="loginArea"
                          aria-selected="true"
                        >
                          LOGIN
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="loginArea"
                        role="tabpanel"
                        aria-labelledby="loginArea-tab"
                      >
                        <div className="login-reg-content">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h3 className="title">Welcome Back</h3>
                                  <p>
                                    Not a member ?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="reg-btn"
                                    >
                                      Register
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="checkbox-item d-flex justify-content-between">
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>Remember me</span>
                                      </span>
                                    </span>
                                  </label>
                                  <a href="javascript:void(0)">Forget Password</a>
                                </div>
                                <div className="btn-area text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="cmn-btn mt-4"
                                  >
                                    Login
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="regArea"
                        role="tabpanel"
                        aria-labelledby="regArea-tab"
                      >
                        <div className="login-reg-content regMode">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h5 className="sub-title">
                                    Ready to get started?
                                  </h5>
                                  <h3 className="title">
                                    Welcome Bonus +50% SC!
                                  </h3>
                                  <p>
                                    Already have an account?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="log-btn"
                                    >
                                      Login
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="First Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Last Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input
                                    type="date"
                                    placeholder="Date of Birth"
                                  />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="sidebar-single-item">
                                  <label className="checkbox-single d-flex">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input
                                          type="checkbox"
                                          defaultChecked="checked"
                                        />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I am at least 18 years of age and I have
                                          read, accepted and agreed to the{" "}
                                          <u>
                                            <a href="javascript:void(0)">
                                              Privacy Policy
                                            </a>
                                            , Bonus T&amp;C,{" "}
                                            <a href="javascript:void(0)">
                                              Terms and Conditions
                                            </a>
                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I want to receive promotions by e-mail.
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <div className="btn-area text-center">
                                    <a
                                      href="javascript:void(0)"
                                      className="cmn-btn mt-4"
                                    >
                                      Get Started Now
                                      <i className="icon-d-right-arrow-2" />
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login Registration end */}
      {/* Banner Section start */}
      <section className="banner-section inner-banner about-us">
        <div className="overlay">
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-10">
                  <div className="main-content">
                    <h2>KYC / AML</h2>
                    <div className="breadcrumb-area">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0)">Pages</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Know Your Customer
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section end */}
      {/* KYC/AML */}
      <section className="privacy-content terms">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <h3>KYC/AML Policy</h3>
                  <p>Version 1.0</p>
                  <p>Effective as of August, 2024, and supersedes and replaces all prior KYC/AML Policies”.</p>
                  <h5>1. INTRODUCTION</h5>
                  <p>
                  Tropic Gamers LLC ("we," "us," or "our") is committed to complying with all applicable laws and regulations regarding anti-money laundering (AML) and know your customer (KYC) procedures. This KYC/AML Policy outlines the measures we take to ensure that UltraSpin operates in accordance with these laws, particularly as required by international financial regulations, and in compliance with the rules and guidelines set forth by our KYC verification provider, Veriff.
                  </p>
                  <h5>2. PURPOSE</h5>
                  <p>
                  The purpose of this policy is to prevent UltraSpin from being used, intentionally or unintentionally, for any illegal activities, including money laundering, terrorism financing, and fraudulent activity. This policy sets the guidelines for the identification of our users and establishes measures for monitoring and reporting any suspicious activity.
                  </p>
                  <h5>3. KYC PROCEDURE</h5>
                  <p>
                  We require users to complete the following steps to verify their identity before they can participate in certain activities on UltraSpin, such as deposits, withdrawals, or any other transaction that exceeds a threshold:
                  </p>
                  <ul>
                    <li>
                    Identity Verification: Users must submit valid government-issued identification (e.g., passport, driver’s license, national ID card) and a recent photo of themselves (selfie).
                    </li>
                    <li>
                    Proof of Address: Users must provide a document proving their residential address, such as a utility bill or bank statement issued within the last three months
                    </li>
                    <li>
                    Date of Birth Verification: Users must be at least 18 years old to register and use UltraSpin. We will verify this through the identification documents provided.
                    </li>
                    <li>
                    Additional Verification: For users engaging in higher transaction limits or upon suspicion of fraud, we may request further documentation or enhanced due diligence.   
                    </li>
                  </ul>
                  <p>
                  All information submitted for KYC purposes will be processed and verified by our partner, Veriff, in accordance with their compliance guidelines and data protection regulations.
                  </p>
                  <h5>4. AML POLICY</h5>
                  <p>
                  The following steps are implemented as part of our AML policy:
                  </p>
                  <ul>
                    <li>
                    Transaction Monitoring: We monitor transactions for suspicious activities, including but not limited to unusually large transactions, repeated transactions over short periods or any transactions deemed unusual.
                    </li>
                    <li>
                    Record Keeping: We will retain the identity verification information, transaction records, and any related documentation for at least five years after the end of the user relationship.
                    </li>
                    <li>
                    Reporting: Any suspicious activity detected will be promptly reported to the relevant authorities, including the Financial Crimes Enforcement Network (FinCEN) or other applicable law enforcement agencies.  
                    </li>
                  </ul>
                  <h5>5. PROHIBITED JURISDICTIONS</h5>
                  <p>
                  Users from the following states and territories are prohibited from using UltraSpin: Washington, Montana, Maryland, Pennsylvania, New Jersey, Connecticut, West Virginia, Louisiana, Rhode Island, Delaware, Nevada, Michigan, and Idaho. Users from any sanctioned countries or territories identified by the Office of Foreign Assets Control (OFAC) are also prohibited from participating.
                  </p>
                  <h5>6. USER RESPONSIBILITIES</h5>
                  <p>
                  By registering for an UltraSpin account, users agree to provide accurate, truthful, and up-to-date information. Users are also required to update their personal details if there are any changes to ensure that our records remain accurate.
                   </p>
                   <p>
                   Failure to comply with our KYC/AML requirements or any suspicious behavior may result in the suspension or termination of the user’s account and notification to the appropriate authorities. 
                   </p>
                  <h5>7. DATA PRIVACY</h5>
                  <p>
                  All personal data collected as part of the KYC process will be handled securely and in compliance with applicable data protection laws, including the General Data Protection Regulation (GDPR) for users in the European Union, and other relevant local privacy regulations. Tropic Gamers LLC is committed to ensuring the privacy and security of user data at all times.
                  </p>
                  <h5>8. POLICY UPDATES</h5>
                  <p>
                  We reserve the right to modify this policy at any time in response to changes in legal or regulatory requirements. Any updates will be communicated to users through our website and will become effective immediately upon posting.
                  </p>
                  <h5>CONTACT INFO</h5>
                  <p>
                  For any questions or concerns related to this KYC/AML Policy, please contact us at:
                  </p>
                  <p>
                    Tropic Gamers LLC<br />
                    300 Delaware Ave, Ste 210<br />
                    Wilmington, DE 19801<br />
                    support@ultraspin.vip
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* KYC/AML */}
      {/* Footer Area Start */}
      <footer className="footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="footer-form">
                <div className="section-area mb-30 text-center">
                  <h4 className="title">To Get Exclusive Benefits</h4>
                </div>
                <form action="#">
                  <div className="form-group input-area d-flex align-items-center">
                    <input type="text" placeholder="Enter your email address" />
                    <button className="btn-area">
                      <img src="assets/images/icon/send-icon.png" alt="icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-area pt-120">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-top d-flex align-items-center justify-content-between">
                  <a href="/home">
                    <img
                      src="assets/images/logo.png"
                      className="logo"
                      alt="logo"
                    />
                  </a>
                  <div className="footer-box">
                    <ul className="footer-link d-flex align-items-center gap-4">
                    <li>
                        <a href="/contact-us">Contact</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/termandconditions">Terms of Service</a>
                      </li>
                      <li>
                        <a href="/kyc">KYC/AML</a>
                      </li>
                      <li>
                        <a href="/rsg">Responsible Social Gaming</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                  <div className="copyright text-center">
                    <p>© Tropic Gamers 2023 | All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                  <div className="social">
                    <ul className="footer-link gap-2 d-flex align-items-center">
                      <li>
                        <a href="javascript:void(0)">
                          <i className="fb fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="ins fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="tw fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="in fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Area End */}
      {/*==================================================================*/}
    </>
  )
  