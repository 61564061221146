import React, { useEffect, useState } from 'react';

const AnnouncementScroller = () => {
  const [index, setIndex] = useState(0);
  const messages = [
    "Welcome to UltraSpin VIP",
    "New games weekly!",
    "Fishing Tournament TBA",
    "Looking for a side hustle?",
    "Join Ultra Affiliates!"
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prev) => (prev + 1) % messages.length);
    }, 4000);
    
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative w-full min-w-[240px] sm:min-w-[300px] md:min-w-[340px] lg:min-w-[400px] xl:min-w-[480px] -mt-2">
      {/* Main container with responsive height */}
      <div className="w-full h-6 sm:h-8 md:h-10 lg:h-12 xl:h-14 rounded-lg p-[1px] sm:p-[2px] overflow-hidden bg-[#1a1a1a]">
        {/* Animated gradient border */}
        <div className="absolute inset-0 animate-borderGlow">
          <div className="w-full h-full bg-gradient-to-r from-[#00f3ff] via-[#945bff] to-[#00f3ff] opacity-70" />
        </div>
        
        {/* Content container with darker background for contrast */}
        <div className="relative w-full h-full rounded-lg bg-[#000000]/95 backdrop-blur-sm overflow-hidden">
          {/* Text container */}
          <div className="absolute inset-0 flex items-center justify-center px-2 sm:px-4">
            <div
              className="text-center font-bold tracking-wide whitespace-nowrap animate-textGlow"
              style={{
                color: '#ffffff',
                fontSize: 'clamp(0.5rem, 1.2vw, 1.4rem)',
                textShadow: `
                  0 0 10px #00f3ff,
                  0 0 20px #00f3ff,
                  0 0 30px #00f3ff,
                  0 0 2px #ffffff
                `
              }}
            >
              {messages[index]}
            </div>
          </div>

          {/* Edge fades */}
          <div className="absolute inset-y-0 left-0 w-4 sm:w-8 bg-gradient-to-r from-black to-transparent" />
          <div className="absolute inset-y-0 right-0 w-4 sm:w-8 bg-gradient-to-l from-black to-transparent" />

          {/* Shine effect */}
          <div className="absolute inset-0 animate-shine" />
        </div>
      </div>

      {/* Background glow */}
      <div className="absolute -inset-1 rounded-lg blur-xl bg-gradient-to-r from-[#00f3ff]/30 via-[#945bff]/30 to-[#00f3ff]/30 animate-pulse -z-10" />
    </div>
  );
};

const style = document.createElement('style');
style.textContent = `
  .animate-borderGlow {
    animation: borderGlow 4s linear infinite;
    background-size: 300% 300%;
  }

  .animate-textGlow {
    animation: textGlow 4s ease-in-out infinite;
  }

  .animate-shine {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 243, 255, 0.2),
      transparent
    );
    animation: shine 3s linear infinite;
    transform: translateX(-100%);
  }

  @keyframes borderGlow {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }

  @keyframes textGlow {
    0%, 100% {
      filter: brightness(1) drop-shadow(0 0 8px rgba(0, 243, 255, 0.8));
    }
    50% {
      filter: brightness(1.4) drop-shadow(0 0 15px rgba(0, 243, 255, 1));
    }
  }

  @keyframes shine {
    to {
      transform: translateX(100%);
    }
  }

  @media (max-width: 767px) {
    .animate-borderGlow,
    .animate-textGlow,
    .animate-shine {
      animation-duration: 6s;
    }
  }
`;
document.head.appendChild(style);

export default AnnouncementScroller;