import React, { useState } from 'react'
import prizewheelImage from '../../images/prizewheel.png'
import './PrizeWheel.css'

const PrizeWheel = ({ userId, onClick }) => {
  const [isSpinning, setIsSpinning] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSpin = async (e) => {
    e.stopPropagation()
    if (isSpinning) return

    setIsSpinning(true)
    setTimeout(() => {
      setIsSpinning(false)
    }, 7000)
  }

  return (
    <>
      <div
        className="prizewheel-container"
        style={{
          position: 'absolute',
          top: '2%',
          left: '50%',
          transform: 'translate(-50%)',
          zIndex: 10,
          cursor: 'pointer',
          opacity: 1,
          width: '11.36vw',
          height: '11.36vw',
          visibility: 'visible',
        }}
        onClick={onClick}
      >
        <img
          src={prizewheelImage}
          alt="Prize Wheel"
          className="prizewheel"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      {isModalOpen && (
        <div
          className="wheel-modal-overlay"
          onClick={() => !isSpinning && setIsModalOpen(false)}
        >
          <div
            className="wheel-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={`modal-prizewheel ${isSpinning ? 'spinning' : ''}`}
              onClick={handleSpin}
            >
              <img
                src={prizewheelImage}
                alt="Prize Wheel in Modal"
                className="wheel-image"
              />
              <div className="number number-1">1</div>
              <div className="number number-2">2</div>
              <div className="number number-3">3</div>
              <div className="number number-4">4</div>
              <div className="number number-5">5</div>
              <div className="number number-6">6</div>
              <div className="number number-7">7</div>
              <div className="number number-8">8</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PrizeWheel
