import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import topbarImage from '../../images/topbar.png'
import Settings from '../Modals/Settings'
import PolicyModal from '../Modals/PolicyModal'
import lobbyMusic from '../../images/lobbymusic.mp3'
import { ROOT_URL } from '../../utils/variable'
import DropdownMenu from './HomeDropdown'
import ProfileUpdateModal from './ProfileUpdateModal'
import TransactionHistoryModal from './TransactionHistoryModal'
import UserRedeemModal from './UserRedeemModal'
import UserGameHistory from './UserGameHistory'
import CoinPackagesModal from './CoinPackageModal'
import { decryptToken } from '../../utils/tokenValidation'

const Topbar = forwardRef((props, ref) => {
  const [goldCoins, setGoldCoins] = useState(0)
  const [sweepsStars, setSweepsStars] = useState(0)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isPolicyOpen, setIsPolicyOpen] = useState(false)
  const [volume, setVolume] = useState(0.5)
  const [audio] = useState(new Audio(lobbyMusic))
  const [isMuted, setIsMuted] = useState(false)
  const [isCoinPackagesOpen, setIsCoinPackagesOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isuserRedeemModalOpen, setuserRedeemModalOpen] = useState(false)
  const [isTransactionModalOpen, setTransactionModalOpen] = useState(false)
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2500)
  const [isUserGameHistory, setUserGameHistory] = useState(false)
  const [layoutReady, setLayoutReady] = useState(false)

  const [leftTextStyles, setLeftTextStyles] = useState({
    ...styles.leftText,
    position: 'absolute',
    color: 'white',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 700,
    opacity: 0, // Start hidden
  })

  const [rightTextStyles, setRightTextStyles] = useState({
    ...styles.rightText,
    position: 'absolute',
    color: 'white',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 700,
    opacity: 0, // Start hidden
  })

  useImperativeHandle(ref, () => ({
    updateBalance(newBalance) {
      if (newBalance) {
        console.log('Received balance update (in pennies):', newBalance)
        setSweepsStars(
          (prevSweepsStars) => prevSweepsStars + parseInt(newBalance)
        )
      }
    },
  }))

  const updateTopBarPositions = () => {
    const lobbyContainer = document.getElementById('lobby-content')
    if (!lobbyContainer) return false

    const lobbyWidth = lobbyContainer.offsetWidth
    const lobbyHeight = lobbyContainer.offsetHeight

    // Only proceed if we have valid dimensions
    if (lobbyWidth <= 0 || lobbyHeight <= 0) return false

    const widthScale = lobbyWidth / 1900
    const heightScale = lobbyHeight / 1069

    setLeftTextStyles((prevStyles) => ({
      ...prevStyles,
      top: `28%`,
      fontSize: `${38 * widthScale}px`,
      left: `16%`,
      opacity: 1, // Show text once positioned
    }))

    setRightTextStyles((prevStyles) => ({
      ...prevStyles,
      top: `28%`,
      fontSize: `${38 * widthScale}px`,
      right: `18%`,
      opacity: 1, // Show text once positioned
    }))

    return true
  }

  useEffect(() => {
    // Function to repeatedly try updating positions until successful
    const attemptUpdatePositions = () => {
      const success = updateTopBarPositions()
      if (success) {
        setLayoutReady(true)
      } else if (!layoutReady) {
        // If not successful and layout isn't ready, try again in 100ms
        setTimeout(attemptUpdatePositions, 100)
      }
    }

    // Start the attempt cycle
    attemptUpdatePositions()

    // Set up resize listener only after initial positioning is successful
    const handleResize = () => {
      updateTopBarPositions()
    }

    if (layoutReady) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [layoutReady])

  // Listen for orientation changes specifically
  useEffect(() => {
    const handleOrientationChange = () => {
      // Reset layout ready state to trigger recalculation
      setLayoutReady(false)
      // Wait for DOM to update after orientation change
      setTimeout(() => {
        updateTopBarPositions()
      }, 100)
    }

    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const sessionToken = decryptToken()
        if (sessionToken) {
          const response = await fetch(`${ROOT_URL}api/users/balance`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${sessionToken}`,
              'Content-Type': 'application/json',
            },
          })
          if (response.ok) {
            const data = await response.json()
            setGoldCoins(parseInt(data.gold_coins))
            setSweepsStars(parseInt(data.sweeps_stars))
          } else {
            console.error('Failed to retrieve balance')
          }
        }
      } catch (error) {
        console.error('Error fetching balance:', error)
        if (error.response && error.response.status === 401) {
          alert('Please login again')
          localStorage.removeItem('authToken')
          window.location.href = '/'
        }
      }
    }
    fetchBalance()
  }, [])

  //(convert from pennies to dollars)
  const formatBalance = (pennies, showDecimals = true) => {
    const dollars = pennies / 100
    return showDecimals ? dollars.toFixed(2) : Math.floor(dollars)
  }

  useEffect(() => {
    const handlePlay = () => {
      audio.volume = volume
      audio.play().catch((error) => console.log('Playback prevented:', error))
    }
    audio.loop = true
    window.addEventListener('click', handlePlay, { once: true })

    return () => {
      window.removeEventListener('click', handlePlay)
    }
  }, [audio, volume])

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2500)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleToggleMute = () => {
    setIsMuted(!isMuted)
    audio.muted = !audio.muted
  }

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume)
    audio.volume = newVolume
  }

  const handleToggleFullscreen = () => {
    const elem = document.getElementById('lobby-content') // The target element
    if (!elem) {
      console.error('Element not found: #lobby-content')
      return
    }

    // Toggle the CSS class for fullscreen
    if (elem.classList.contains('fullscreen')) {
      console.log('removed the full screen')
      elem.classList.remove('fullscreen') // Exit fullscreen by removing the class
    } else {
      console.log('made the full screen')
      elem.classList.add('fullscreen') // Enter fullscreen by adding the class
    }
  }

  const handlePayment = () => {
    setIsCoinPackagesOpen(true)
  }

  const handleHomeClick = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleDropdownItemClick = (item) => {
    setIsDropdownOpen(false)
    if (item === 'Buy Coins') {
      setIsCoinPackagesOpen(true)
    }
    if (item === 'Redeem') {
      setuserRedeemModalOpen(true)
    } else if (item === 'Update Profile') {
      setModalOpen(true)
    } else if (item === 'Transactions') {
      setTransactionModalOpen(true)
    } else if (item === 'Game history') {
      setUserGameHistory(true)
    }
  }

  const [isLandscape, setIsLandscape] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const checkOrientation = () => {
    const landscape = window.matchMedia('(orientation: landscape)').matches
    setIsLandscape(landscape)
    setIsMobile(window.innerWidth < 968)
  }

  useEffect(() => {
    checkOrientation()
    window.addEventListener('resize', checkOrientation)
    window.addEventListener('orientationchange', checkOrientation)
    return () => {
      window.removeEventListener('resize', checkOrientation)
      window.removeEventListener('orientationchange', checkOrientation)
    }
  }, [])

  return (
    <div style={styles.container}>
      <div className="top-bar-wrapper">
        <img src={topbarImage} alt="Topbar" style={styles.image} />

        <div style={leftTextStyles}>{formatBalance(goldCoins, false)} GC</div>
        <div style={rightTextStyles}>{formatBalance(sweepsStars)} SS</div>
      </div>

      <div style={styles.leftClickableArea} onClick={handleHomeClick} />
      <div
        style={styles.rightClickableArea}
        onClick={() => setIsSettingsOpen(true)}
      />
      <div style={styles.centerClickableArea} onClick={handlePayment} />
      <div
        style={styles.bottomClickableArea}
        onClick={() => setIsPolicyOpen(true)}
      />

      <Settings
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        volume={volume}
        onVolumeChange={handleVolumeChange}
        onToggleMute={handleToggleMute}
        isMuted={isMuted}
        onToggleFullscreen={handleToggleFullscreen}
      />

      <PolicyModal
        isOpen={isPolicyOpen}
        onClose={() => setIsPolicyOpen(false)}
      />

      <DropdownMenu
        isOpen={isDropdownOpen}
        onItemClick={handleDropdownItemClick}
      />

      <ProfileUpdateModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
      <CoinPackagesModal
        isOpen={isCoinPackagesOpen}
        onClose={() => setIsCoinPackagesOpen(false)}
      />
      <TransactionHistoryModal
        isOpen={isTransactionModalOpen}
        onClose={() => setTransactionModalOpen(false)}
      />
      <UserRedeemModal
        isOpen={isuserRedeemModalOpen}
        onClose={() => setuserRedeemModalOpen(false)}
      />
      <UserGameHistory
        isOpen={isUserGameHistory}
        onClose={() => setUserGameHistory(false)}
      />
    </div>
  )
})

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    zIndex: 3,
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
    inset: 0,
    left: 0,
    top: 0,
    objectFit: 'cover',
  },
  leftText: {
    position: 'absolute',
  },
  rightText: {
    position: 'absolute',
  },
  leftClickableArea: {
    position: 'absolute',
    left: '1%',
    top: '10%',
    width: '5%',
    height: '70%',
    cursor: 'pointer',
  },
  rightClickableArea: {
    position: 'absolute',
    right: '1%',
    top: '10%',
    width: '5%',
    height: '75%',
    cursor: 'pointer',
  },
  centerClickableArea: {
    position: 'absolute',
    left: '29%',
    top: '50%',
    width: '2%',
    height: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
  bottomClickableArea: {
    position: 'absolute',
    right: '6%',
    top: '15%',
    width: '5%',
    height: '50%',
    transform: 'translate(-50%, 0)',
    cursor: 'pointer',
  },
}

export default Topbar
