import React from 'react';

const PayHelp = () => {
    const handleFacebookClick = () => {
        window.open('https://www.facebook.com/profile.php?id=61568070916101', '_blank');
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-black p-3 sm:p-5">
            {/* Logo placeholder */}
            <div className="w-24 sm:w-32 mb-4 sm:mb-6">
                <img 
                    src="/api/placeholder/128/128"
                    alt="Logo"
                    className="w-full h-auto"
                />
            </div>

            <div className="bg-purple-900 p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full max-w-2xl text-center">
                <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                    {/* Help Icon */}
                    <div className="text-4xl sm:text-6xl mb-2 sm:mb-4">💬</div>

                    <div className="space-y-3 sm:space-y-4">
                        <h1 className="text-2xl sm:text-3xl font-bold text-white mb-2 sm:mb-4">New to Crypto Payments?</h1>
                        <p className="text-base sm:text-lg text-gray-300 mb-4 sm:mb-6 px-2 sm:px-0">
                            Don't worry! We're here to guide you through every step of the process. Once you've done it once or twice, you've done it 1000 times. 
                        </p>

                        {/* Support Options */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 mb-6 sm:mb-8">
                            <div className="flex flex-col items-center p-3 sm:p-4 bg-purple-800 rounded-lg">
                                <span className="text-3xl sm:text-4xl mb-2">🏠</span>
                                <h3 className="text-white font-semibold mb-1 sm:mb-2">Live Chat Support</h3>
                                <p className="text-gray-300 text-xs sm:text-sm">Available directly from our homepage</p>
                            </div>

                            <div className="flex flex-col items-center p-3 sm:p-4 bg-purple-800 rounded-lg">
                                <span className="text-3xl sm:text-4xl mb-2">📱</span>
                                <h3 className="text-white font-semibold mb-1 sm:mb-2">Facebook Messenger</h3>
                                <p className="text-gray-300 text-xs sm:text-sm">Connect with us on Facebook</p>
                            </div>
                        </div>

                        {/* Support Hours */}
                        <div className="bg-purple-800 p-4 rounded-lg mb-6">
                            <h3 className="text-xl text-white font-semibold mb-2">Support Hours</h3>
                            <p className="text-gray-300 text-sm sm:text-base">
                                Live Customer Support Available:
                            </p>
                            <p className="text-white font-medium text-base sm:text-lg">
                                2:00 PM - 4:00 AM EST
                            </p>
                            <p className="text-purple-300 text-sm">
                                7 Days per Week, 365 Days per Year
                            </p>
                        </div>

                        {/* Main Content */}
                        <div className="text-left space-y-3 sm:space-y-4 text-gray-300 text-sm sm:text-base px-2 sm:px-0">
                            <p>
                                Whether you're making your first crypto payment or need assistance with an existing 
                                transaction, our dedicated support team is here to help. We provide step-by-step 
                                guidance and real-time assistance to ensure a smooth payment and redemption experience.
                            </p>
                        </div>

                        {/* Facebook Button */}
                        <button 
                            onClick={handleFacebookClick}
                            className="w-full sm:w-auto mt-6 sm:mt-8 px-4 sm:px-6 py-2 sm:py-3 bg-purple-600 text-white text-sm sm:text-base rounded-lg hover:bg-purple-500 transition-colors duration-300 flex items-center justify-center"
                        >
                            <span className="mr-2">Connect on Facebook</span>
                            <span className="text-xl">👋</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayHelp;