import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';
import { decryptToken } from '../../../utils/tokenValidation';

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #038ea1;
  color: white;
  padding-top: 40px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 5%;
`;

const MainHeading = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #fff;
  margin-left: 2%;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #a76ef2;
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  max-width: 150px;

  &:hover {
    background-color: #8a5bc9;
  }
`;

const InputFieldContainer = styled.div`
  margin-bottom: 15px;
`;

const InputField = ({ name, placeholder, type = 'text', value, onChange, required = false }) => (
  <InputFieldContainer>
    <input
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      required={required}
      style={{
        padding: '10px',
        borderRadius: '0px',
        border: 'none',
        maxWidth: '350px',
        width: '100%',
      }}
    />
  </InputFieldContainer>
);

const GgrReportForm = ({ formData, handleInputChange, handleSubmit, errors }) => (
  <form onSubmit={handleSubmit}>
    <InputField
      name="report_type"
      placeholder="Report Type (monthly, custom, etc.)"
      value={formData.report_type}
      onChange={handleInputChange}
      required
    />
    {errors.report_type && <span style={{ color: 'red' }}>{errors.report_type}</span>}

    <InputField
      name="start_date"
      placeholder="Start Date (YYYY-MM-DD)"
      value={formData.start_date}
      onChange={handleInputChange}
      type="date"
    />
    {errors.start_date && <span style={{ color: 'red' }}>{errors.start_date}</span>}

    <InputField
      name="end_date"
      placeholder="End Date (YYYY-MM-DD)"
      value={formData.end_date}
      onChange={handleInputChange}
      type="date"
    />
    {errors.end_date && <span style={{ color: 'red' }}>{errors.end_date}</span>}

    <SubmitButton type="submit">Generate Report</SubmitButton>
  </form>
);

const Table = styled.table`
  width: 90%;
  margin-top: 20px;
  border-collapse: collapse;
  color: #fff;
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #007B8F;
  border: 1px solid #ddd;
  color:#fff;
`;

const TableRow = styled.tr`
  background-color: #024E68;
  color:#fff;
`;

const TableCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
`;

const Content = ({ selectedMenu }) => {
  const [formData, setFormData] = useState({
    report_type: 'today',
    start_date: '',
    end_date: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const fetchGgrReport = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const sessionToken = decryptToken();
      const headers = {
        Authorization: `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      };

      const { report_type, start_date, end_date } = formData;

      const formattedStartDate = new Date(start_date).toISOString();
      const formattedEndDate = new Date(end_date).toISOString();

      const url = `${ROOT_URL}admin/ggr-report?report_type=${report_type}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

      const response = await axios.get(url, { headers });

      setLoading(false);
      setReportData(response.data);
      setSuccessMessage('Report generated successfully!');
    } catch (error) {
      setLoading(false);
      console.error('Error fetching report:', error.response ? error.response.data : error.message);
      setErrorMessage('An error occurred while fetching the report.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchGgrReport();
  };

  return (
    <ContentContainer>
      {selectedMenu === 'Stats' && (
        <FormContainer>
          <MainHeading>GGR REPORT</MainHeading>
          {successMessage && <div style={{ color: '#fff' }}>{successMessage}</div>}
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          <GgrReportForm
            formData={formData}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            errors={errors}
          />
          {loading && <div>Loading report...</div>}
          {reportData && (
            <div>
              <h2>Report Data</h2>
              <Table>
                <thead>
                  <tr>
                    <TableHeader>GGR</TableHeader>
                    <TableHeader>Total Deposit</TableHeader>
                    <TableHeader>Total Redeemed</TableHeader>
                    <TableHeader>Start Date</TableHeader>
                    <TableHeader>End Date</TableHeader>
                    <TableHeader>Report Type</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <TableRow>
                    <TableCell>{reportData.ggr}</TableCell>
                    <TableCell>{reportData.total_deposit}</TableCell>
                    <TableCell>{reportData.total_redeemed}</TableCell>
                    <TableCell>{reportData.start_date}</TableCell>
                    <TableCell>{reportData.end_date}</TableCell>
                    <TableCell>{reportData.report_type}</TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </div>
          )}
        </FormContainer>
      )}
    </ContentContainer>
  );
};

export default Content;
