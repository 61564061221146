import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Lobby from './pages/Lobby';
import { HomePage } from './components/HomePage';
import { ContactUs } from './components/ContactUs';
import { TermCondition } from './components/TermCondition';
import AdminPanel from './components/AdminPanel/Main';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import { PrivacyPoliy } from './components/PrivacyPolicy';
import Sudo from './components/Sudo';
import EmailVerified from './pages/EmailSucess';
import KYCSucess from './pages/KYCSucess';
import ResetPassword from './pages/ResetPassword';
import AffiliatePanel from './components/AffiliatePanel/Main';
import { RSGpolicy } from './components/RSG';
import { KYCpolicy } from './components/KYC';
import { FAQ } from './components/FAQ';
import BitcoinHelp from './components/Helpful';
import LightningInfo from './pages/Lightning';
import PayHelp from './pages/PayHelp';
import Instant from './pages/Instant';
import RedeemInfo from './pages/RedeemInfo';

const TawkWrapper = ({ children }) => {
  const location = useLocation();
  const isLobbyPage = location.pathname === '/lobby';

  useEffect(() => {
    if (!isLobbyPage) {
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/671fe51d4304e3196ad9aaa2/1ibfe0igf';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);

      return () => {
        s0.parentNode.removeChild(s1);
      };
    }
  }, [isLobbyPage]); // Run this effect every time the page changes

  return (
    <>
      {/* Only show the Tawk bubble if we are not on the lobby page */}
      {!isLobbyPage && <div id="tawk_671fe51d4304e3196ad9aaa2"></div>}
      {children}
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <TawkWrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPoliy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/termandconditions" element={<TermCondition />} />
            <Route path="/rsg" element={<RSGpolicy />} />
            <Route path="/kyc" element={<KYCpolicy />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/Sudo" element={<Sudo />} />
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/affiliate-panel" element={<AffiliatePanel />} />
            <Route path="/lobby" element={<Lobby />} />
            <Route path="/email-verified-success" element={<EmailVerified />} />
            <Route path="/kyc-success" element={<KYCSucess />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/helpful" element={<BitcoinHelp />} />
            <Route path="/lightning" element={<LightningInfo />} />
            <Route path="/payment-help" element={<PayHelp />} />
            <Route path="/instant" element={<Instant />} />
            <Route path="/redeem-info" element={<RedeemInfo />} />
           
            {/* Add redirect from /games to /lobby */}
            <Route path="/games" element={<Navigate to="/lobby" replace />} />
          </Routes>
        </TawkWrapper>
      </Router>
    </AuthProvider>
  );
};

export default App;
