import React from 'react';

const RedeemInfo = () => {
    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-black p-3 sm:p-5">
            {/* Logo placeholder */}
            <div className="w-24 sm:w-32 mb-4 sm:mb-6">
                <img 
                    src="/api/placeholder/128/128"
                    alt="Logo"
                    className="w-full h-auto"
                />
            </div>

            <div className="bg-purple-900 p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full max-w-2xl text-center">
                <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                    {/* Redeem Icon */}
                    <div className="text-4xl sm:text-6xl mb-2 sm:mb-4">💰</div>

                    <div className="space-y-3 sm:space-y-4">
                        <h1 className="text-2xl sm:text-3xl font-bold text-white mb-2 sm:mb-4">Redeem Information</h1>

                        {/* Processing Time Box */}
                        <div className="bg-purple-800 p-4 rounded-lg mb-6">
                            <h3 className="text-xl text-white font-semibold mb-2">Fast Processing Times</h3>
                            <p className="text-gray-300 text-sm sm:text-base">
                                Most redeems are processed within
                            </p>
                            <p className="text-white font-medium text-lg sm:text-xl">
                                30-60 Minutes
                            </p>
                            <p className="text-purple-300 text-sm mt-2">
                                During support hours: 2:00 PM - 4:00 AM EST, 7 Days a Week
                            </p>
                        </div>

                        {/* Redeem Limits */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 mb-6">
                            <div className="bg-purple-800 p-4 rounded-lg">
                                <span className="text-2xl sm:text-3xl">⬇️</span>
                                <h3 className="text-white font-semibold my-2">Minimum Redeem</h3>
                                <p className="text-2xl text-white font-bold">$75</p>
                            </div>

                            <div className="bg-purple-800 p-4 rounded-lg">
                                <span className="text-2xl sm:text-3xl">⬆️</span>
                                <h3 className="text-white font-semibold my-2">Maximum Redeem</h3>
                                <p className="text-2xl text-white font-bold">$500</p>
                                <p className="text-sm text-purple-300">per 24 hours</p>
                            </div>
                        </div>

                        {/* Process Information */}
                        <div className="text-left space-y-4 text-gray-300 text-sm sm:text-base">
                            <div className="bg-purple-800 p-4 rounded-lg mb-4">
                                <h3 className="text-white font-semibold mb-2">How It Works</h3>
                                <ol className="space-y-2 list-decimal pl-4">
                                    <li>Submit your redeem request</li>
                                    <li>Amount is immediately removed from your game balance</li>
                                    <li>Continue playing while your redeem processes</li>
                                    <li>Receive your funds via selected payment method</li>
                                </ol>
                            </div>

                            <p>
                                When you choose instant payment options like Coinbase Visa Debit or Cash App 
                                Lightning Network, your funds become available for immediate use as soon as we 
                                process your redeem.
                            </p>

                            <div className="bg-purple-800 p-4 rounded-lg">
                                <h3 className="text-white font-semibold mb-2">Get Instant Access</h3>
                                <p className="text-sm">
                                    Using Coinbase Visa Debit or Cash App Lightning Network means you can:
                                </p>
                                <ul className="list-none space-y-2 mt-2">
                                    <li>✓ Access funds immediately after processing</li>
                                    <li>✓ Spend directly with Visa Debit</li>
                                    <li>✓ Transfer instantly to your bank</li>
                                </ul>
                            </div>

                            <div className="bg-purple-700 p-4 rounded-lg mt-4 text-sm">
                                <p className="text-white">
                                    <span className="font-semibold">Note:</span> In rare cases, additional verification 
                                    may be required before processing. We'll notify you if this applies to your redeem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RedeemInfo;