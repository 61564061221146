import React from 'react';

const Instant = () => {
    const handleCoinbaseLearnMore = () => {
        window.open('https://www.coinbase.com/card', '_blank');
    };

    const handleCashAppLearnMore = () => {
        window.open('https://cash.app/bitcoin', '_blank');
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-black p-3 sm:p-5">
            {/* Logo placeholder */}
            <div className="w-24 sm:w-32 mb-4 sm:mb-6">
                <img 
                    src="/api/placeholder/128/128"
                    alt="Logo"
                    className="w-full h-auto"
                />
            </div>

            <div className="bg-purple-900 p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full max-w-2xl text-center">
                <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                    {/* Instant Icon */}
                    <div className="text-4xl sm:text-6xl mb-2 sm:mb-4">⚡</div>

                    <div className="space-y-3 sm:space-y-4">
                        <h1 className="text-2xl sm:text-3xl font-bold text-white mb-2 sm:mb-4">Instant Access to Your Funds</h1>
                        
                        {/* Highlight Box */}
                        <div className="bg-purple-800 p-4 rounded-lg mb-6">
                            <p className="text-xl sm:text-2xl text-white font-semibold">
                                Yes, your funds can be available instantly!
                            </p>
                        </div>

                        {/* Service Options */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                            <div className="bg-purple-800 p-4 rounded-lg flex flex-col h-full">
                                <span className="text-3xl mb-2">💳</span>
                                <h3 className="text-white font-semibold text-lg mb-2">Coinbase</h3>
                                <ul className="text-gray-300 text-sm text-left space-y-2 mb-4 flex-grow">
                                    <li>• Visa Debit Card available</li>
                                    <li>• Instant crypto-to-fiat conversion</li>
                                    <li>• Widely accepted payment method</li>
                                    <li>• User-friendly interface</li>
                                </ul>
                                <button 
                                    onClick={handleCoinbaseLearnMore}
                                    className="mt-auto w-full py-2 bg-purple-600 text-white rounded hover:bg-purple-500 transition-colors duration-300"
                                >
                                    Learn More
                                </button>
                            </div>

                            <div className="bg-purple-800 p-4 rounded-lg flex flex-col h-full">
                                <span className="text-3xl mb-2">📱</span>
                                <h3 className="text-white font-semibold text-lg mb-2">Cash App</h3>
                                <ul className="text-gray-300 text-sm text-left space-y-2 mb-4 flex-grow">
                                    <li>• Built-in Lightning support</li>
                                    <li>• Instant withdrawals</li>
                                    <li>• Easy mobile access</li>
                                    <li>• Quick setup process</li>
                                </ul>
                                <button 
                                    onClick={handleCashAppLearnMore}
                                    className="mt-auto w-full py-2 bg-purple-600 text-white rounded hover:bg-purple-500 transition-colors duration-300"
                                >
                                    Learn More
                                </button>
                            </div>
                        </div>

                        {/* Detailed Information */}
                        <div className="text-left space-y-4 text-gray-300 text-sm sm:text-base">
                            <p>
                                Gone are the days of waiting hours or days to access your crypto funds. With modern 
                                services supporting Lightning Network and instant withdrawals, you can use your 
                                crypto immediately after receiving it.
                            </p>
                            <p>
                                Whether you choose Coinbase's Visa Debit Card for immediate spending power or 
                                Cash App's Lightning Network integration for instant transfers, you're never more 
                                than a few taps away from accessing your funds.
                            </p>
                            <p>
                                Both platforms offer:
                            </p>
                            <ul className="list-none space-y-2 pl-4">
                                <li>✓ Real-time transactions</li>
                                <li>✓ Secure fund management</li>
                                <li>✓ User-friendly mobile apps</li>
                                <li>✓ 24/7 availability</li>
                            </ul>
                        </div>

                        {/* Final CTA */}
                        <div className="bg-purple-800 p-4 rounded-lg mt-6">
                            <p className="text-white text-sm sm:text-base">
                                Ready to experience truly instant crypto transactions? Choose the platform that best 
                                suits your needs and start enjoying immediate access to your funds today.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instant;