import React, { useState, useEffect, useCallback, useMemo } from 'react'
import './GameGrid.css'
import SudoAnimation from './../Sudo/SudoAnimation'
import UserImage from '../../images/avatar.png'
import heartFaded from '../../images/heart-faded.png'
import heartColored from '../../images/heart-colored.png'
import { ROOT_URL } from '../../utils/variable'
import GameModal from '../Modals/GameModal'
import { decryptToken } from '../../utils/tokenValidation'
import AnnouncementScroller from './AnnouncementScroller.js'
import useEmblaCarousel from 'embla-carousel-react'
import './embla.css'

const GameThumbnail = React.memo(
  ({ game, onGameClick, isFavorite, onFavoriteClick }) => (
    <div
      className="grid-item visible"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log('Thumbnail clicked, game data:', game)
        onGameClick(game)
      }}
    >
      <div className="image-wrapper">
        <img
          src={game.media || 'https://via.placeholder.com/250'}
          alt={game.name}
          className="grid-image"
          loading="lazy"
          decoding="async"
          fetchpriority="low"
        />
        <div
          className="heart-icon"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            console.log('Heart clicked for game:', game.json_id)
            onFavoriteClick(game.json_id)
          }}
        >
          <img
            src={isFavorite ? heartColored : heartFaded}
            alt="Favorite"
            className="heart-icon-image"
          />
        </div>
      </div>
    </div>
  )
)

GameThumbnail.displayName = 'GameThumbnail'

const GameGrid = ({
  games = [],
  loading = false,
  error,
  hasMore = false
}) => {
  console.log('GameGrid received props:', {
    gamesLength: games.length,
    loading,
    error,
    hasMore,
  })
  const [emblaRef] = useEmblaCarousel({})
  const [displayedGames, setDisplayedGames] = useState([])
  const [favorites, setFavorites] = useState(new Set()) // Initialize with an empty set

  // Fetch the favorites from the API
  useEffect(() => {
    const fetchFavorites = async () => {
      const token = decryptToken()
      try {
        const response = await fetch('https://api.ultraspin.vip/game/favorites', {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`, // Replace with actual token
          },
        })

        if (!response.ok) {
          throw new Error('Failed to fetch favorites')
        }

        const data = await response.json()
        const gameIds = new Set(data.map((game) => game.game_id))
        setFavorites(gameIds)
      } catch (error) {
        console.error('Error fetching favorites:', error)
      }
    }
    const fetchUser = async () => {
      const sessionToken = decryptToken()
      if (!sessionToken) return

      try {
        const response = await fetch(`${ROOT_URL}api/users/me`, {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
        })

        if (response.ok) {
          const data = await response.json()
          setUser(data)
        }
      } catch (error) {
        console.error('Error fetching user details:', error)
      }
    }

    fetchUser()
    setDisplayedGames(splitIntoChunks(games, isMobileDevice ? 8 : 12))
    fetchFavorites()
  }, [games])

  const [user, setUser] = useState(null)
  const [selectedGame, setSelectedGame] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [gameData, setGameData] = useState({
    status: null,
    message: null,
    error: null,
    game: null,
    balances: null,
  })

  const isMobileDevice = useMemo(() => {
    if (typeof window === 'undefined') return false

    const userAgent =
      window.navigator.userAgent || window.navigator.vendor || window.opera
    const mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    const isMobileScreen = window.innerWidth <= 768

    return mobileRegex.test(userAgent) || isMobileScreen
  }, [])
  function splitIntoChunks(array, chunkSize) {
    if (!Array.isArray(array)) {
      throw new Error('First argument must be an array')
    }
    if (typeof chunkSize !== 'number' || chunkSize <= 0) {
      throw new Error('Chunk size must be a positive number')
    }

    const result = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }

    return result
  }

  const handleGameClick = useCallback((game) => {

    setSelectedGame(game)
    setIsModalOpen(true)

    const sessionToken = decryptToken()

    const fetchBalance = async () => {
      try {
        const balanceResponse = await fetch(`${ROOT_URL}api/users/balance`, {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            Accept: 'application/json',
          },
        })

        console.log('Balance response status:', balanceResponse.status)

        if (balanceResponse.ok) {
          const balanceData = await balanceResponse.json()
          console.log('Balance data received:', balanceData)
          setGameData({
            status: null,
            message: null,
            error: null,
            game: game,
            balances: balanceData,
          })
        } else {
          throw new Error('Failed to fetch balance')
        }
      } catch (error) {
        console.error('Error in fetchBalance:', error)
        setGameData({
          status: 'error',
          message: 'Failed to fetch balance',
          error: error.message,
          game: game,
        })
      }
    }
    fetchBalance()
  }, [])

  const initGameSession = useCallback(
    async (currency) => {
      const sessionToken = decryptToken()
      if (!sessionToken || !user || !selectedGame) {
        setGameData((prev) => ({
          ...prev,
          status: 'error',
          message: 'Please log in to play',
          error: 'Authentication required',
        }))
        return
      }

      setGameData((prev) => ({
        ...prev,
        status: 'loading',
        message: 'Initializing game session...',
      }))

      try {
        let clientIP = ''
        try {
          const ipResponse = await fetch('https://api.ipify.org?format=json')
          if (ipResponse.ok) {
            const ipData = await ipResponse.json()
            clientIP = ipData.ip
          }
        } catch (error) {
          console.warn('Could not fetch IP address:', error)
        }

        const payload = {
          game_id: selectedGame.json_id,
          player_id: user.id.toString(),
          ip_address: clientIP,
          currency: currency === 'gold_coins' ? 'GOC' : 'SSC',
          mobile: isMobileDevice ? 1 : 0,
          return_url: window.location.origin + '/games',
          deposit_url: window.location.origin + '/deposit',
          extra: '',
          debug: process.env.NODE_ENV === 'development',
        }

        console.log('Initializing game with payload:', payload)

        const response = await fetch(`${ROOT_URL}game/games/init`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })

        if (response.ok) {
          const data = await response.json()
          // Updated to match backend response structure
          if (data.status === 200 && data.response.game_url) {
            setGameData((prev) => ({
              ...prev,
              status: 'success',
              message: 'Game session initialized!',
              url: data.response.game_url,
              token: data.response.token, // Also storing the token if needed
            }))

            setTimeout(() => {
              window.location.href = data.response.game_url
            }, 1000)
          } else {
            throw new Error('Invalid response format from server')
          }
        } else {
          const errorData = await response.json()
          setGameData((prev) => ({
            ...prev,
            status: 'error',
            message: errorData.message || 'Failed to initialize game',
            error: errorData.message,
          }))
        }
      } catch (error) {
        console.error('Error initializing game:', error)
        setGameData((prev) => ({
          ...prev,
          status: 'error',
          message: 'Failed to connect to game server',
          error: error.message,
        }))
      }
    },
    [user, selectedGame, isMobileDevice]
  )

  const toggleFavorite = useCallback(
    async (gameId) => {
      const isFavorite = favorites.has(gameId)
      const url = `${ROOT_URL}game/favorites/add?game_id=${gameId}`

      try {
        // If game is not already a favorite, add it
        if (!isFavorite) {
          const token = decryptToken()
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          if (response.ok) {
            // Update the state to reflect the new favorite
            setFavorites((prev) => {
              const newFavorites = new Set(prev)
              newFavorites.add(gameId)
              localStorage.setItem(
                'favorites',
                JSON.stringify([...newFavorites])
              )
              return newFavorites
            })
          } else {
            console.error('Failed to add favorite', response)
          }
        } else {
          const token = decryptToken()
          const response = await fetch(
            `${ROOT_URL}game/favorites/remove?game_id=${gameId}`,
            {
              method: 'DELETE', // Assuming DELETE method for removing favorites
              headers: {
                accept: 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          if (response.ok) {
            // Update the state to remove the favorite
            setFavorites((prev) => {
              const newFavorites = new Set(prev)
              newFavorites.delete(gameId)
              localStorage.setItem(
                'favorites',
                JSON.stringify([...newFavorites])
              )
              return newFavorites
            })
          } else {
            console.error('Failed to remove favorite', response)
          }
        }
      } catch (error) {
        console.error('Error toggling favorite', error)
      }
    },
    [favorites]
  )

  useEffect(() => {
    setDisplayedGames(splitIntoChunks(games, isMobileDevice ? 8 : 12))
  }, [games, isMobileDevice]);
  
  return (
    <div className="w-100 d-flex align-items-center justify-content-between flex-grow-1">
      <div className="d-flex flex-column">
        <div className="z-10 d-flex flex-column">
          <div className="d-flex align-items-center justify-content-center">
            <img src={UserImage} alt="User Avatar" className="user-avatar" />
            <div className="user-text">{user?.username ?? 'Loading...'}</div>
          </div>
          <AnnouncementScroller />
        </div>
        <div className="animation-container">
          <SudoAnimation />
        </div>
      </div>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {displayedGames.length === 0 ? (
              <div className="games-message">No games available</div>
            ) : (
              displayedGames.map((chunk, index) => (
                <div className="embla__slide row" key={index}>
                  {chunk.map((game, index) => (
                    <GameThumbnail
                      key={`${game.json_id}-${index}`}
                      game={game}
                      onGameClick={handleGameClick}
                      isFavorite={favorites.has(game.json_id)}
                      onFavoriteClick={toggleFavorite}
                    />
                  ))}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <GameModal
          onClose={() => {
            setIsModalOpen(false)
            setSelectedGame(null)
            setGameData({
              status: null,
              message: null,
              error: null,
              game: null,
              balances: null,
            })
          }}
          gameData={gameData}
          onPlayGoldCoins={() => initGameSession('gold_coins')}
          onEnterSweepstakes={() => initGameSession('sweep_stars')}
          loading={gameData.status === 'loading'}
        />
      )}
    </div>
  )
}

export default GameGrid
