import React, { useState } from 'react';
import cashapp from '../../images/cashapp.png';
import coinbase from '../../images/coinbase.png';
import kraken from '../../images/kraken.png';
import binance from '../../images/binance.png';

const BitcoinHelpPage = () => {
  const [activeTab, setActiveTab] = useState('benefits');

  const handleBackToGame = () => {
    window.location.href = '/';
  };

  const paymentApps = [
    {
      name: 'Cash App',
      icon: cashapp,
      steps: [
        'Open Cash App and tap the Bitcoin tab (₿)',
        'Tap "Send" and then "Send Bitcoin"',
        'Choose "Lightning" as payment method',
        'Paste the Lightning invoice or scan QR code',
        'Confirm the amount and slide to pay'
      ]
    },
    {
      name: 'Coinbase',
      icon: coinbase,
      steps: [
        'Open Coinbase and go to "Send/Receive"',
        'Select Bitcoin as the asset',
        'Tap "Lightning Network"',
        'Paste Lightning invoice or scan QR',
        'Review and confirm payment'
      ]
    },
    {
      name: 'Kraken',
      icon: kraken,
      steps: [
        'Navigate to "Funding" in Kraken',
        'Select Bitcoin and "Withdraw"',
        'Choose "Lightning Network"',
        'Enter the Lightning invoice',
        'Confirm withdrawal details'
      ]
    },
    {
      name: 'Binance',
      icon: binance,
      steps: [
        'Go to Wallet > Withdraw',
        'Select BTC and "Lightning Network"',
        'Input the Lightning invoice',
        'Verify withdrawal amount',
        'Complete security verification'
      ]
    }
  ];

  const benefits = [
    {
      icon: '⚡',
      title: 'Instant Transactions',
      description: 'Lightning-fast payments that settle in seconds'
    },
    {
      icon: '💰',
      title: 'Minimal Fees',
      description: 'Nearly zero-cost transactions compared to traditional methods'
    },
    {
      icon: '🛡️',
      title: 'Enhanced Privacy',
      description: 'Improved transaction privacy and security'
    },
    {
      icon: '➡️',
      title: 'Seamless Integration',
      description: 'Works with major cryptocurrency exchanges and wallets'
    }
  ];

  const styles = {
    mainContainer: {
      backgroundColor: '#1a0b3b',
      minHeight: '100vh',
      color: 'white',
      fontFamily: 'Arial, sans-serif'
    },
    header: {
      backgroundColor: '#200d47',
      padding: '20px',
      position: 'fixed',
      width: '100%',
      top: 0,
      zIndex: 1000
    },
    backButton: {
      backgroundColor: '#00ff00',
      color: 'black',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '20px',
      cursor: 'pointer',
      fontWeight: 'bold'
    },
    tabButton: {
      backgroundColor: '#2a1155',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '20px',
      cursor: 'pointer',
      margin: '0 10px'
    },
    activeTabButton: {
      backgroundColor: '#4a2175',
      color: 'white'
    },
    content: {
      marginTop: '155px',
      padding: '20px',
      maxWidth: '1200px',
      margin: '220px auto 0'
    },
    card: {
      backgroundColor: '#2a1155',
      padding: '20px',
      borderRadius: '10px',
      marginBottom: '20px'
    },
    appIcon: {
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      marginRight: '12px',
      objectFit: 'contain'
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '20px'
    },
    appHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px'
    }
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.header}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '1200px', margin: '0 auto' }}>
          <h1>Bitcoin Lightning Payments Guide</h1>
          <button style={styles.backButton} onClick={handleBackToGame}>
            Back to Home
          </button>
        </div>
        <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <button
            style={{
              ...styles.tabButton,
              ...(activeTab === 'benefits' ? styles.activeTabButton : {})
            }}
            onClick={() => setActiveTab('benefits')}
          >
            Benefits
          </button>
          <button
            style={{
              ...styles.tabButton,
              ...(activeTab === 'howto' ? styles.activeTabButton : {})
            }}
            onClick={() => setActiveTab('howto')}
          >
            How to Pay
          </button>
        </div>
      </div>

      <div style={styles.content}>
        {activeTab === 'benefits' ? (
          <div style={styles.gridContainer}>
            {benefits.map((benefit, index) => (
              <div key={index} style={styles.card}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <span style={{ fontSize: '24px', marginRight: '10px' }}>{benefit.icon}</span>
                  <h3 style={{ margin: 0 }}>{benefit.title}</h3>
                </div>
                <p style={{ color: '#b8b8b8' }}>{benefit.description}</p>
              </div>
            ))}
          </div>
        ) : (
          <div style={styles.gridContainer}>
            {paymentApps.map((app, index) => (
              <div key={index} style={styles.card}>
                <div style={styles.appHeader}>
                  <img 
                    src={app.icon} 
                    alt={`${app.name} icon`} 
                    style={styles.appIcon}
                  />
                  <h3 style={{ margin: 0 }}>{app.name}</h3>
                </div>
                <ol style={{ margin: 0, paddingLeft: '20px' }}>
                  {app.steps.map((step, stepIndex) => (
                    <li key={stepIndex} style={{ color: '#b8b8b8', marginBottom: '10px' }}>
                      {step}
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BitcoinHelpPage;
