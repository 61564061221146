import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 620px;
  background-color: #580059;
  color: white;
  padding: 20px;
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  position: relative;
  font-family: Arial, sans-serif;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 15px;
  background: none;
  border: none;
  color: red;
  font-size: 32px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    color: #ff7f7f;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 14px;
  span {
    color: #fff !important;
    font-weight: 600 !important;
  }
`;

const PaymentOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: justify;
  cursor: pointer;

  img {
    width: 56px;
    height: 56px;
    object-fit: contain;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-left: 6px solid white;
  padding-left: 15px;
  text-align: justify;
  cursor: pointer;
`;

const HelpItem = styled.div`
  font-size: 16px;
  color: #fff !important;
  font-weight: 600 !important;
  cursor: pointer;
`;

const IframeContainer = styled.div`
  margin-top: 20px;
  width: 750px;
  height: 450px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  /* Media query for landscape mode on mobile (max-width: 768px, min-width: 481px) */
  @media (max-width: 968px) and (orientation: landscape) {
    width: 100%;
    height: 400px; /* Adjust height for landscape mode */
  }

`;


const IframeModal = ({ isOpen, onClose, iframeUrl }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <IframeContainer>
          <iframe title="Help Content" src={iframeUrl} allowFullScreen />
        </IframeContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

const Modal = ({ isOpen, onClose, buyNow }) => {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(null);

  useEffect(() => {
    // Add tawk.to script when the modal is opened
    if (isOpen) {
      if (typeof window !== "undefined" && !window.Tawk_API) {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://embed.tawk.to/671fe51d4304e3196ad9aaa2/1ibfe0igf";
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        document.body.appendChild(script);
      }
    }

    // Cleanup the tawk.to script when the modal is closed
    return () => {
      if (!isOpen && window.Tawk_API) {
        const scripts = document.querySelectorAll("script[src*='tawk.to']");
        scripts.forEach((script) => script.remove());
      }
    };
  }, [isOpen]); // Dependencies on `isOpen` to handle cleanup and script addition

  const handleOpenTawkChat = () => {
    if (window.Tawk_API) {
      window.Tawk_API.toggle();
    }
  };

  const handleHelpItemClick = (helpType) => {
    const url = getHelpUrl(helpType);
    setIframeUrl(url);
    setIsIframeOpen(true);  // Open iframe modal
  };

  const getHelpUrl = (helpType) => {
    switch (helpType) {
      case "LIGHTNING":
        return "/lightning";
      case "NEW":
        return "/payment-help";
      case "FUNDS":
        return "/instant";
      case "REDEEM":
        return "/redeem-info";
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <LeftSection>
            <h3>PAY WITH:</h3>
            <PaymentOption onClick={buyNow}>
              <img src="/lightning.png" alt="Bitcoin Lightning" />
              <span>BITCOIN LIGHTNING</span>
            </PaymentOption>
            <PaymentOption onClick={buyNow}>
              <img src="/coinbase.png" alt="Coinbase" />
              <span>COINBASE</span>
            </PaymentOption>
            <PaymentOption onClick={handleOpenTawkChat}>
              <img src="/cashapp.png" alt="Cash App" />
              <span>CASH APP OR CHIME (CONTACT SUPPORT)</span>
            </PaymentOption>
          </LeftSection>
          <RightSection>
            <h3>HELP</h3>
            <HelpItem onClick={() => handleHelpItemClick("LIGHTNING")}>
              WHAT IS LIGHTNING?
            </HelpItem>
            <HelpItem onClick={() => handleHelpItemClick("NEW")}>
              I’M NEW, CAN YOU HELP ME?
            </HelpItem>
            <HelpItem onClick={() => handleHelpItemClick("FUNDS")}>
              INSTANT ACCESS TO YOUR FUNDS
            </HelpItem>
            <HelpItem onClick={() => handleHelpItemClick("REDEEM")}>
              REDEEM INFO
            </HelpItem>
          </RightSection>
        </ModalContent>
      </ModalOverlay>

      {/* Show the IframeModal */}
      <IframeModal
        isOpen={isIframeOpen}
        onClose={() => setIsIframeOpen(false)}
        iframeUrl={iframeUrl}
      />
    </>
  );
};

export default Modal;
