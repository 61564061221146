import React, { useEffect, useState } from 'react';
import axios from 'axios';
import userIcon from '../../../images/asset1.png'; 
import emailIcon from '../../../images/asset8.png'; 
import phoneIcon from '../../../images/asset9.png'; 
import passwordIcon from '../../../images/asset10.png'; 
import { ROOT_URL } from '../../../utils/variable';
import { decryptToken } from '../../../utils/tokenValidation';

const ProfileUpdateModal = ({ isOpen, onClose }) => {
  const [userData, setUserData] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(''); 
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [isLandscape, setIsLandscape] = useState(window.matchMedia("(max-width: 968px) and (orientation: landscape)").matches);

  useEffect(() => {
    if (isOpen) {
      fetchUserData();
    }
  }, [isOpen]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 968px) and (orientation: landscape)");
    const handleResize = () => setIsLandscape(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const fetchUserData = async () => {
    const sessionToken = decryptToken();
    
    try {
      const response = await axios.get(`${ROOT_URL}api/users/me`, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });
      setUserData(response.data);
      setUsername(response.data.username);
      setEmail(response.data.email);
      setPhone(response.data.phone || ''); 
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        alert('Please login again');
        localStorage.removeItem('authToken');
        window.location.href = '/';
      }
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    const sessionToken = decryptToken();

    try {
      await axios.put(`${ROOT_URL}api/user/update`, { email, phone, password }, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });
      alert('Profile updated successfully!');
      onClose(); 
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile.');
    }
  };

  return (
    isOpen && (
      <div style={modalStyles.overlay}>
        <div style={{...modalStyles.modal, ...(isLandscape ? modalStyles.landscapeModal : {})}}>
          <div style={{...modalStyles.svgContainer, ...(isLandscape ? modalStyles.landscapeSvgContainer : {})}}>
            <svg width="375" height="" viewBox="0 0 716 173" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M714.86 25.035C713.37 24.945 711.12 24.895 708.42 25.155C704.32 25.555 698.96 26.075 695.78 29.055C692.17 32.435 692.35 38.065 692.4 40.175C692.82 57.935 712.92 172.505 621.36 172.505C529.8 172.505 66.76 172.505 66.76 172.505C66.76 172.505 19.02 168.585 17.06 119.535V40.155C17.04 39.515 16.65 31.155 9.70001 27.015C5.61001 24.585 1.48001 24.935 0.0100098 25.135C0.28001 23.115 1.79 20.885 10.56 13.955C18.52 7.66502 27.21 7.76502 29.48 7.86502H695.26C695.26 7.86502 716.7 0.295017 714.86 25.035Z" fill="#EA8500"/>
              <path d="M715.1 24.605C713.61 24.515 711.36 24.465 708.66 24.725C704.56 25.125 699.2 25.645 696.02 28.625C692.41 32.005 692.59 37.635 692.64 39.745C693.06 57.505 712.91 164.665 621.35 164.665C529.79 164.665 66.75 164.665 66.75 164.665C66.75 164.665 19.01 160.745 17.05 111.695V39.755C17.03 39.115 16.64 30.755 9.69 26.615C5.6 24.185 1.47 24.535 0 24.735C0.27 22.715 1.77999 13.055 10.55 6.11502C18.51 -0.174977 27.2 -0.0749806 29.47 0.0250194H695.25C695.26 0.0150194 716.94 -0.144979 715.1 24.605Z" fill="url(#paint0_linear_23_6)"/>
              <defs>
                <linearGradient id="paint0_linear_23_6" x1="357.882" y1="-1.17058" x2="356.193" y2="150.842" gradientUnits="userSpaceOnUse">
                  <stop offset="1.38522e-07" stop-color="#EBAE27"/>
                  <stop offset="0.0855" stop-color="#F9B815"/>
                  <stop offset="0.1814" stop-color="#FBEA28"/>
                  <stop offset="0.519" stop-color="#FDD132"/>
                  <stop offset="1" stop-color="#FEC833"/>
                </linearGradient>
              </defs>
            </svg>
            <h2 style={modalStyles.title}>UPDATE PROFILE</h2>
          </div>
          <div style={{...(isLandscape ? modalStyles.landscapeInputContainer : {})}}>          
          <div style={{...modalStyles.inputGroup, ...(isLandscape ? modalStyles.landscapeInputGroup : {})}}>
          <div style={{...modalStyles.inputWrapper, ...(isLandscape ? modalStyles.landscapeinputWrapper : {})}}>
                <img src={userIcon} alt="User Icon" style={modalStyles.icon} />
                <input 
                  type="text" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)} 
                  style={modalStyles.input} 
                  placeholder="Username"
                />
              </div>
            </div>
            <div style={{...modalStyles.inputGroup, ...(isLandscape ? modalStyles.landscapeInputGroup : {})}}>
            <div style={{...modalStyles.inputWrapper, ...(isLandscape ? modalStyles.landscapeinputWrapper : {})}}>
                <img src={emailIcon} alt="Email Icon" style={modalStyles.icon} />
                <input 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  style={modalStyles.input} 
                  placeholder="Email"
                />
              </div>
            </div>
            <div style={{...modalStyles.inputGroup, ...(isLandscape ? modalStyles.landscapeInputGroup : {})}}>
            <div style={{...modalStyles.inputWrapper, ...(isLandscape ? modalStyles.landscapeinputWrapper : {})}}>
                <img src={phoneIcon} alt="Phone Icon" style={modalStyles.icon} />
                <input 
                  type="tel" 
                  value={phone} 
                  onChange={(e) => setPhone(e.target.value)} 
                  style={modalStyles.input} 
                  placeholder="Phone"
                />
              </div>
            </div>
            <div style={{...modalStyles.inputGroup, ...(isLandscape ? modalStyles.landscapeInputGroup : {})}}>
            <div style={{...modalStyles.inputWrapper, ...(isLandscape ? modalStyles.landscapeinputWrapper : {})}}>
                <img src={passwordIcon} alt="Password Icon" style={modalStyles.icon} />
                <input 
                  type="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  style={modalStyles.input} 
                  placeholder="Password"
                />
              </div>
            </div>
          </div>
          <div style={modalStyles.buttonContainer}>
            <button style={modalStyles.updateButton} onClick={handleUpdate}><h2>UPDATE</h2></button>
            <button style={modalStyles.logoutButton} onClick={onClose}>
              <h2>CANCEL</h2>
            </button>          
          </div>
        </div>
      </div>
    )
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#690DAC',
    color: 'white',
    padding: '20px',
    borderRadius: '48px',
    border: '12px solid #F7CEB2',
    maxWidth: '675px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3), 0 0 40px rgba(255, 204, 0, 0.5)', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  landscapeModal: {
    padding: '15px',
    borderRadius: '24px',
    maxWidth: '85%',
    height: '95vh',
  },
  svgContainer: {
    position: 'relative',
    width: '100%',
    display:'flex',
    transform:'translateY(-40px)',
    justifyContent:'center',
    alignItems:'center'
  },
  landscapeSvgContainer:{
    position: 'relative',
    width: '100%',
    display:'flex',
    transform:'translateY(0px)',
    justifyContent:'center',
    alignItems:'center'
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '32px',
    fontWeight: '700',
    color: '#000',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  landscapeInputGroup:{
    marginBottom: '2px',
  },
  landscapeInputContainer:{
    height:'85%',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    backgroundColor: '#142D5B',
    border: '2px solid #0A162C',
    padding: '5px',
  },
  landscapeinputWrapper:{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    backgroundColor: '#142D5B',
  },

  icon: {
    marginRight: '12px',
    width: '32px',
    height: '32px',
  },
  input: {
    width: '100%',
    padding: '10px',
    color: '#606060',
    fontSize: '16px',
    fontWeight: '500',
    backgroundColor: 'transparent',
    border: 'none',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    gap: '20px',
  },
  updateButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
    padding: '6px 10px',
    backgroundColor: '#3A1BD8',
    border: '6px solid #F7CEB2',
    borderRadius: '36px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '32px',
    fontWeight: '800',
    color: '#fff',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
    width: '25%',
  },
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 10px',
    backgroundColor: 'red',
    border: '6px solid #F7CEB2',
    borderRadius: '36px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '32px',
    fontWeight: '800',
    color: '#fff',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
    width: '25%',
  },
};

export default ProfileUpdateModal;
