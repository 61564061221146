import React from 'react'

const SplashScreen = () => {
  return (
    <div className="fixed inset-0 bg-white flex items-center justify-center flex-col">
      <div className="w-24 h-24 mb-4"></div>
      <h1 className="text-2xl font-bold text-gray-800">PWA</h1>
      <p className="text-gray-600 mt-2">Loading amazing content...</p>
    </div>
  )
}

export default SplashScreen
