import React, { useState, useEffect } from 'react'
import { decryptToken } from '../../utils/tokenValidation'
const LoadingSpinner = ({ children }) => (
  <>
    <svg
      className="animate-spin h-3 w-3 mr-2"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
    {children}
  </>
)

const GameModal = ({
  onClose,
  gameData,
  onPlayGoldCoins,
  onEnterSweepstakes,
  loading,
}) => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const addRecentGame = async (gameId) => {
    const token = decryptToken()
    if (!token) {
      console.error('No token available. User may not be authenticated.')
      return
    }

    try {
      const response = await fetch(
        `https://api.ultraspin.vip/game/recent-games/add?game_id=${gameId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) {
        console.error('Failed to add recent game:', response.statusText)
      }
    } catch (error) {
      console.error('Error adding recent game:', error)
    }
  }

  const handlePlayGoldCoins = () => {
    if (gameData?.game?.json_id) {
      addRecentGame(gameData.game.json_id)
      onPlayGoldCoins('GOC')
    }
  }

  const handleEnterSweepstakes = () => {
    if (gameData?.game?.json_id) {
      addRecentGame(gameData.game.json_id)
      onEnterSweepstakes('SSC')
    }
  }

  if (!gameData?.game) {
    return null
  }

  const game = gameData.game
  const isMobile = screenSize.width <= 768
  const isSmallMobile = screenSize.width <= 360

  const modalStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: isMobile ? 'flex-end' : 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 9999,
    padding: isMobile ? '0.5rem 0.5rem 0 0.5rem' : '1rem',
  }

  const contentStyles = {
    backgroundColor: '#1f2937',
    borderRadius: isMobile ? '1rem 1rem 0 0' : '0.5rem',
    padding: isSmallMobile ? '1rem' : isMobile ? '1.125rem' : '1.5rem',
    width: '100%',
    maxWidth: '750px',
    maxHeight: isMobile ? '80vh' : '90vh',
    color: 'white',
    boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    zIndex: 10000,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
  }

  const closeButtonStyle = {
    position: 'absolute',
    top: isSmallMobile ? '0.5rem' : isMobile ? '0.75rem' : '1rem',
    right: isSmallMobile ? '0.5rem' : isMobile ? '0.75rem' : '1rem',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    border: 'none',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: 'white',
    fontSize: '1.25rem',
    zIndex: 1,
  }

  return (
    <div style={modalStyles} onClick={onClose}>
      <div style={contentStyles} onClick={(e) => e.stopPropagation()}>
        <button style={closeButtonStyle} onClick={onClose}>
          ×
        </button>

        {gameData.status === 'error' && (
          <div
            style={{
              backgroundColor: 'rgba(239, 68, 68, 0.1)',
              border: '1px solid rgb(239, 68, 68)',
              borderRadius: '0.375rem',
              padding: '0.75rem',
              marginBottom: '0.75rem',
              color: 'rgb(239, 68, 68)',
              fontSize: isSmallMobile ? '0.813rem' : '0.875rem',
            }}
          >
            {gameData.message || 'An error occurred'}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isSmallMobile ? '0.75rem' : isMobile ? '1rem' : '1.5rem',
          }}
        >
          <div
            style={{
              flex: isMobile ? '0 0 auto' : '0 0 33.333333%',
              marginTop: isMobile ? '0.5rem' : 0,
            }}
          >
            <h2
              style={{
                fontSize: isSmallMobile
                  ? '1rem'
                  : isMobile
                  ? '1.125rem'
                  : '1.25rem',
                fontWeight: 'bold',
                marginBottom: '0.5rem',
              }}
            >
              {game.name}
            </h2>
            <img
              src={game.media || '/api/placeholder/400/320'}
              alt={game.name}
              style={{
                width: '100%',
                height: isSmallMobile ? '150px' : isMobile ? '180px' : '16rem',
                objectFit: 'cover',
                borderRadius: '0.375rem',
              }}
            />
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: isSmallMobile ? '0.75rem' : '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.375rem',
              }}
            >
              <InfoCard
                label="Min spin"
                value="coming soon"
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
              />
              <InfoCard
                label="Max spin"
                value="coming soon"
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
              />
              <InfoCard
                label="Max Win Multiplier"
                value="coming soon"
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
              />

              <div
                style={{
                  backgroundColor: 'black',
                  borderRadius: '0.375rem',
                  padding: isSmallMobile ? '0.5rem' : '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{
                    fontSize: isSmallMobile
                      ? '0.75rem'
                      : isMobile
                      ? '0.813rem'
                      : '0.875rem',
                    fontWeight: 'bold',
                    color: 'rgb(156, 163, 175)',
                  }}
                >
                  Volatility
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <span
                    style={{
                      fontSize: isSmallMobile ? '0.813rem' : '0.875rem',
                    }}
                  >
                    TBD
                  </span>
                  <div
                    style={{
                      width: isSmallMobile
                        ? '3rem'
                        : isMobile
                        ? '4rem'
                        : '6rem',
                      height: '0.375rem',
                      borderRadius: '9999px',
                      background:
                        'linear-gradient(to right, rgb(239, 68, 68), rgb(34, 197, 94))',
                    }}
                  />
                </div>
              </div>
            </div>

            {gameData.status === 'loading' && (
              <div
                style={{
                  color: 'rgb(96, 165, 250)',
                  fontSize: isSmallMobile ? '0.813rem' : '0.875rem',
                }}
              >
                {gameData.message || 'Loading...'}
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: isSmallMobile ? '0.5rem' : '0.75rem',
                marginTop: 'auto',
                paddingBottom: isMobile ? '0.5rem' : 0,
              }}
            >
              <Button
                onClick={handlePlayGoldCoins}
                disabled={loading || gameData.status === 'loading'}
                variant="secondary"
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
              >
                {loading ? (
                  <LoadingSpinner>Loading...</LoadingSpinner>
                ) : (
                  'Play with Gold Coins'
                )}
              </Button>
              <Button
                onClick={handleEnterSweepstakes}
                disabled={loading || gameData.status === 'loading'}
                variant="primary"
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
              >
                {loading ? (
                  <LoadingSpinner>Loading...</LoadingSpinner>
                ) : (
                  'Enter Sweepstakes'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const InfoCard = ({ label, value, isMobile, isSmallMobile }) => (
  <div
    style={{
      backgroundColor: 'black',
      borderRadius: '0.375rem',
      padding: isSmallMobile ? '0.5rem' : isMobile ? '0.625rem' : '0.75rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <span
      style={{
        fontSize: isSmallMobile
          ? '0.75rem'
          : isMobile
          ? '0.813rem'
          : '0.875rem',
        fontWeight: 'bold',
        color: 'rgb(156, 163, 175)',
      }}
    >
      {label}
    </span>
    <span
      style={{
        fontSize: isSmallMobile ? '0.813rem' : isMobile ? '0.875rem' : '1rem',
      }}
    >
      {value}
    </span>
  </div>
)

const Button = ({
  children,
  variant = 'secondary',
  isMobile,
  isSmallMobile,
  ...props
}) => {
  const baseStyles = {
    width: '100%',
    padding: isSmallMobile ? '0.75rem' : isMobile ? '0.875rem' : '0.75rem 1rem',
    borderRadius: '0.375rem',
    fontWeight: 'bold',
    transition: 'all 200ms',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 1,
    fontSize: isSmallMobile ? '0.875rem' : isMobile ? '0.938rem' : '1rem',
  }

  const variantStyles =
    variant === 'primary'
      ? {
          backgroundColor: 'rgb(34, 197, 94)',
          color: 'white',
          ':hover': { backgroundColor: 'rgb(22, 163, 74)' },
        }
      : {
          backgroundColor: 'black',
          color: 'rgb(156, 163, 175)',
          ':hover': { backgroundColor: 'rgb(17, 24, 39)' },
        }

  return (
    <button style={{ ...baseStyles, ...variantStyles }} {...props}>
      {children}
    </button>
  )
}

export default GameModal
