// DeviceRotationPrompt.js
import React, { useState, useEffect } from 'react';
import rotateImage from '../images/rotate.png';

const DeviceRotationPrompt = ({ onRotated }) => {
  const [showPrompt, setShowPrompt] = useState(true);
 
  useEffect(() => {
    const checkOrientation = () => {
      const isPortrait = window.innerHeight > window.innerWidth;
      setShowPrompt(isPortrait);
      onRotated(!isPortrait); // Pass true when in landscape, false when in portrait
    };

    // Initial check
    checkOrientation();

    // Debounced event handler
    let timeoutId;
    const debouncedCheck = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        checkOrientation();
      }, 100);
    };

    window.addEventListener('resize', debouncedCheck);
    window.addEventListener('orientationchange', debouncedCheck);

    // Also listen to orientation API if available
    if (window.screen?.orientation) {
      window.screen.orientation.addEventListener('change', debouncedCheck);
    }
 
    return () => {
      window.removeEventListener('resize', debouncedCheck);
      window.removeEventListener('orientationchange', debouncedCheck);
      if (window.screen?.orientation) {
        window.screen.orientation.removeEventListener('change', debouncedCheck);
      }
      clearTimeout(timeoutId);
    };
  }, [onRotated]);

  if (!showPrompt) return null;

  return (
    <div className="fixed inset-0 z-12 flex items-center justify-center">
      <div className="text-center p-8">
        <div className="mx-auto mb-6 w-32 h-32">
          <img
            src={rotateImage}
            alt="Rotate device"
            className="w-full h-full animate-[spin_2s_linear_infinite]"
          />
        </div>
        <h2 className="text-2xl font-bold text-white mb-4">
          Please Rotate Your Device
        </h2>
        <p className="text-gray-300">
          For the best experience, please rotate your device to landscape mode
        </p>
      </div>
    </div>
  );
};

export default DeviceRotationPrompt;