import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';
import { decryptToken } from '../../../utils/tokenValidation';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #024e68;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  color: white;
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
  color:#fff;
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007b8f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #005f6b;
  }
`;

const CancelButton = styled(Button)`
  background-color: #555;

  &:hover {
    background-color: #333;
  }
`;

const AffiliateEditModal = ({ affiliate, closeModal }) => {
  const [formData, setFormData] = useState({
    commission_percentage: affiliate.commission_percentage, // Only commission_percentage to update
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const sessionToken = decryptToken();
      const headers = {
        'Authorization': `Bearer ${sessionToken}`,
      };

      // Update the endpoint to the correct one
      await axios.put(
        `${ROOT_URL}admin/affiliate/${affiliate.affiliate_id}/commission?commission_percentage=${formData.commission_percentage}`,
        {},
        { headers }
      );

      closeModal(); // Close the modal after saving
    } catch (error) {
      console.error('Error updating affiliate commission:', error);
    }
  };

  return (
    <ModalBackground>
      <ModalContent>
        <ModalHeader>Edit Affiliate Commission</ModalHeader>
        <Label htmlFor="commission_percentage">Commission Percentage (%)</Label>
        <ModalInput
          type="number"
          name="commission_percentage"
          value={formData.commission_percentage}
          onChange={handleInputChange}
          placeholder="Commission Percentage"
        />

        <ModalActions>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <Button onClick={handleSaveChanges}>Save</Button>
        </ModalActions>
      </ModalContent>
    </ModalBackground>
  );
};

export default AffiliateEditModal;
