import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../Sidebar';
import Header from '../Header';
import CreateUser from '../CreateUser';
import CreateAffiliate from '../CreateAffiliate';
import UsersList from '../UsersList';
import AffiliateList from '../Affiliate';
import Redeems from '../Redeems';
import GGRReport from '../GGRReport';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1536px;
  margin: 0 auto;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

function AdminMain() {
  const [selectedMenu, setSelectedMenu] = useState('Create Affiliate');

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const renderContent = () => {
    console.log('Selected Menu:',selectedMenu);

    switch (selectedMenu) {
      case 'Create User':
        return <CreateUser selectedMenu={selectedMenu} />;
      case 'Create Affiliate':
        return <CreateAffiliate selectedMenu={selectedMenu} />;
      case 'Users':
        return <UsersList selectedMenu={selectedMenu} />;
      case 'Affiliates':
        return <AffiliateList selectedMenu={selectedMenu} />;
      case 'Redeems':
        return <Redeems selectedMenu={selectedMenu} />;
      case 'Stats':
        return <GGRReport selectedMenu={selectedMenu} />;
      default:
        return <div>Please select a menu item.</div>;
    }
  };
  return (
    <AppContainer>
      <Header />
      <MainContent>
        <Sidebar onMenuClick={handleMenuClick} />
        {renderContent()}
      </MainContent>
    </AppContainer>
  );
}

export default AdminMain;
