import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 825px;
  color: #333;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  background-color: #007b8f;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 15px;

  &:hover {
    background-color: #005f6b;
  }
`;

const ModalTitle = styled.h2`
  margin: 0 0 15px;
  font-size: 1.5rem;
  color: #007b8f;
  text-align: center;
`;

const HistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const HistoryTableHeader = styled.th`
  background-color: #007b8f;
  color: white;
  padding: 10px;
  text-align: left;
`;

const HistoryTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const HistoryTableCell = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
`;

function HistoryModal({ userId, history, onClose }) {
  console.log('HISTORY IN HISTORY MODEL:',history);
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Redeem History for User ID: {userId}</ModalTitle>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <HistoryTable>
          <thead>
            <tr>
              <HistoryTableHeader>Created Date</HistoryTableHeader>
              <HistoryTableHeader>Updated Date</HistoryTableHeader>
              <HistoryTableHeader>Amount</HistoryTableHeader>
              <HistoryTableHeader>Status</HistoryTableHeader>
            </tr>
          </thead>
          <tbody>
            {history.length > 0 ? (
              history.map((item, index) => (
                <HistoryTableRow key={index}>
                  <HistoryTableCell>{item.created_at}</HistoryTableCell>
                  <HistoryTableCell>{item.updated_at}</HistoryTableCell>
                  <HistoryTableCell>{item.amount}</HistoryTableCell>
                  <HistoryTableCell>{item.status}</HistoryTableCell>
                </HistoryTableRow>
              ))
            ) : (
              <tr>
                <HistoryTableCell colSpan="3" style={{ textAlign: 'center' }}>
                  No history available.
                </HistoryTableCell>
              </tr>
            )}
          </tbody>
        </HistoryTable>
      </ModalContent>
    </ModalOverlay>
  );
}

export default HistoryModal;
