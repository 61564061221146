import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ROOT_URL } from '../../../utils/variable';
import { decryptToken } from '../../../utils/tokenValidation';

const TableContainer = styled.div`
  width: 100%;
  padding: 20px;
  color: white;
  background-color: #007b8f;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 4px;
`;

const StatsContainer = styled.div`
  margin-top: 20px;
  color: #fff;
`;

const StatsTitle = styled.h3`
  margin-bottom: 10px;
`;

const StatsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

const StatsRow = styled.tr`
  &:nth-child(even) {
    background-color: #024e68;
  }
`;

const StatsCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

function AffiliateLists() {
  const [affiliate, setAffiliate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAffiliateData();
  }, []);

  const fetchAffiliateData = async () => {
    try {
      const sessionToken = decryptToken();
      const affiliateID = localStorage.getItem('affiliate_id');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      const response = await axios.get(`${ROOT_URL}api/affiliate/stats?affiliate_id=${affiliateID}`, { headers });
      console.log('AFFILIATE RESPONSE IN PANEL:', response.data);
      setAffiliate(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching affiliate data:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <TableContainer>
      {/* Hardcoded Stats Section */}
      <StatsContainer>
        <StatsTitle>Affiliate Statistics</StatsTitle>
        {affiliate ? (
          <StatsTable>
            <thead>
              <tr>
                <TableHeader>Name</TableHeader>
                <TableHeader>Player Count</TableHeader>
                <TableHeader>Commission %</TableHeader>
                <TableHeader>Estimated Commission (MTD)</TableHeader>
              </tr>
            </thead>
            <tbody>
              <StatsRow>
                <StatsCell>{affiliate.name}</StatsCell>
                <StatsCell>{affiliate.player_count}</StatsCell>
                <StatsCell>{affiliate.commission_percentage}%</StatsCell>
                <StatsCell>{affiliate.estimated_commission_mtd}</StatsCell>
              </StatsRow>
            </tbody>
          </StatsTable>
        ) : (
          <p>No affiliate data available</p>
        )}
      </StatsContainer>
    </TableContainer>
  );
}

export default AffiliateLists;
