import React from 'react';

const LightningInfo = () => {
    const handleLearnMore = () => {
        window.open('https://www.coinbase.com/card', '_blank');
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-black p-3 sm:p-5">
            {/* Logo placeholder - responsive sizing */}
            <div className="w-24 sm:w-32 mb-4 sm:mb-6">
                <img 
                    src="/api/placeholder/128/128"
                    alt="Logo"
                    className="w-full h-auto"
                />
            </div>

            <div className="bg-purple-900 p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full max-w-2xl text-center">
                <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                    {/* Lightning Symbol */}
                    <div className="text-4xl sm:text-6xl mb-2 sm:mb-4">⚡</div>

                    <div className="space-y-3 sm:space-y-4">
                        <h1 className="text-2xl sm:text-3xl font-bold text-white mb-2 sm:mb-4">Bitcoin Lightning Payments</h1>
                        <p className="text-base sm:text-lg text-gray-300 mb-4 sm:mb-6 px-2 sm:px-0">
                            Experience the future of instant Bitcoin transactions with Lightning Network!
                        </p>

                        {/* Features Grid - responsive columns */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 md:gap-6 mb-6 sm:mb-8">
                            <div className="flex flex-col items-center p-3 sm:p-4 bg-purple-800 rounded-lg">
                                <span className="text-3xl sm:text-4xl mb-2">⚡</span>
                                <h3 className="text-white font-semibold mb-1 sm:mb-2">Instant Transfers</h3>
                                <p className="text-gray-300 text-xs sm:text-sm">Transactions complete in seconds, not minutes or hours!</p>
                            </div>

                            <div className="flex flex-col items-center p-3 sm:p-4 bg-purple-800 rounded-lg">
                                <span className="text-3xl sm:text-4xl mb-2">🔒</span>
                                <h3 className="text-white font-semibold mb-1 sm:mb-2">Secure & Reliable</h3>
                                <p className="text-gray-300 text-xs sm:text-sm">Built on Bitcoin's proven security</p>
                            </div>

                            <div className="flex flex-col items-center p-3 sm:p-4 bg-purple-800 rounded-lg sm:col-span-2 md:col-span-1">
                                <span className="text-3xl sm:text-4xl mb-2">💳</span>
                                <h3 className="text-white font-semibold mb-1 sm:mb-2">Easy, Instant Access</h3>
                                <p className="text-gray-300 text-xs sm:text-sm">Use the Coinbase Visa Debit Card (or even Cash App) for instant spending or ATM Withdraws</p>
                            </div>
                        </div>

                        {/* Main Content - responsive text and spacing */}
                        <div className="text-left space-y-3 sm:space-y-4 text-gray-300 text-sm sm:text-base px-2 sm:px-0">
                            <p>
                                To send and receive lightning payments, you first need a crypto app (or exchange) that supports the
                                Lightning Network. We reccomend Coinbase but this can even be done on Cash App! Lightning is growing
                                in popularity due to it's speed and near 0 fees. Many apps are starting to support it.
                            </p>
                            <p>
                                With Lightning, you don't send from wallet to wallet like traditional crypto. Lightning works on what
                                is known as invoices. On UltraSpin, choosing Bitcoin Lightning generates an invoice for the amount you
                                have selected. Use the QR code to quickly pay in an app of your choice, copy the invoice address or simply
                                choose 'Pay in Wallet' to open the app of your choice for a more seamless experience. Contact Us if you
                                are having difficulty or need some guidance. We're happy to help!
                            </p>
                        </div>

                        {/* Call to Action Button - responsive sizing */}
                        <button 
                            onClick={handleLearnMore}
                            className="w-full sm:w-auto mt-6 sm:mt-8 px-4 sm:px-6 py-2 sm:py-3 bg-[#8400ff] hover:bg-[#9f3afd] text-white text-sm sm:text-base rounded-lg transition-colors duration-300 font-medium"
                        >
                            More About Coinbase Visa Card
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LightningInfo;