import React, { useState, useCallback, useEffect } from 'react'
import lowerbarImage from '../../images/lowerbar.png'
import PrizeWheel from './PrizeWheel'
import icon1 from '../../images/all.png'
import icon2 from '../../images/jackpot.png'
import icon3 from '../../images/fish.png'
import icon4 from '../../images/fav.png'
import icon5 from '../../images/table.png'
import icon6 from '../../images/other.png'
import './Lowerbar.css'
import { decryptToken } from '../../utils/tokenValidation'
import { ROOT_URL } from '../../utils/variable'

const Lowerbar = ({
  onFilter,
  userId,
  onSearchIconClick,
  onSpinIconClick,
  fetchCoolDownTimeState,
}) => {
  console.log('fetchCoolDownTimeState:/meme:', fetchCoolDownTimeState)
  const BASE_CONTAINER_WIDTH = 1900
  const BASE_CONTAINER_HEIGHT = 1069
  const LARGE_SCREEN_THRESHOLD = 1200
  const [cooldownTime, setCooldownTime] = useState(0)

  const [componentStyles, setComponentStyles] = useState({
    iconsContainer: {},
    navIcon: {},
    iconLabel: {},
    timerDisplay: {},
    iconsGroup: {},
  })

  useEffect(() => {
    const updateStyles = () => {
      const lobbyContainer = document.getElementById('lobby-content')
      if (!lobbyContainer) return

      const lobbyWidth = lobbyContainer.offsetWidth
      const lobbyHeight = lobbyContainer.offsetHeight

      const widthScale = Math.min(lobbyWidth / BASE_CONTAINER_WIDTH, 2.5)
      const heightScale = Math.min(lobbyHeight / BASE_CONTAINER_HEIGHT, 2.5)
      const baseScale = Math.min(widthScale, heightScale)

      // Progressive scaling for icons
      let progressiveScale = baseScale
      if (lobbyWidth > LARGE_SCREEN_THRESHOLD) {
        const extraScale =
          (lobbyWidth - LARGE_SCREEN_THRESHOLD) /
          (BASE_CONTAINER_WIDTH - LARGE_SCREEN_THRESHOLD)
        progressiveScale = baseScale * (1 + Math.min(extraScale, 1.2))
      }

      // Timer position based on container width
      let timerBottom
      if (lobbyWidth > 2100) {
        timerBottom = 10
      } else if (lobbyWidth > 1900) {
        timerBottom = 14
      } else if (lobbyWidth > 1700) {
        timerBottom = 16
      } else if (lobbyWidth > 1500) {
        timerBottom = 19
      } else if (lobbyWidth > 1200) {
        timerBottom = 22
      } else if (lobbyWidth > 1000) {
        timerBottom = 34
      } else if (lobbyWidth > 750) {
        timerBottom = 26
      } else {
        timerBottom = 16
      }

      // Timer scaling (keeping the current scaling logic)
      const timerScale = baseScale
      const timerBaseSize = 28
      const timerProgressiveScale =
        timerScale * (lobbyWidth > LARGE_SCREEN_THRESHOLD ? 1.4 : 1)
      const timerFontSize = Math.max(
        16,
        Math.min(timerBaseSize * timerProgressiveScale, 60)
      )

      // Regular icon calculations remain the same
      const iconSize = Math.max(35, Math.min(45 * progressiveScale, 130))
      const fontSize = Math.max(12, Math.min(16 * progressiveScale, 30))
      const iconGap = Math.max(20, Math.min(40 * progressiveScale, 160))

      setComponentStyles({
        iconsContainer: {
          width: `${Math.min(90, 85 + baseScale * 5)}%`,
          maxWidth: `${Math.min(2800, 1800 + baseScale * 800)}px`,
          bottom: `${Math.max(3, Math.min(12 * baseScale, 15))}%`,
        },
        navIcon: {
          height: `${iconSize}px`,
          width: `${iconSize}px`,
        },
        iconLabel: {
          fontSize: `${fontSize}px`,
          marginTop: `${Math.max(3, Math.min(4 * progressiveScale, 16))}px`,
        },
        timerDisplay: {
          fontSize: `${timerFontSize}px`,
          bottom: `${timerBottom}%`,
        },
        iconsGroup: {
          gap: `${iconGap}px`,
        },
      })
    }

    updateStyles()
    window.addEventListener('resize', updateStyles)
    return () => window.removeEventListener('resize', updateStyles)
  }, [])

  // Memoize the function to fetch the cooldown time
  const fetchCooldownTime = useCallback(async () => {
    try {
      console.log('FETCH TIME API CALLLED!')
      const sessionToken = decryptToken()
      if (!sessionToken) throw new Error('No authentication token found')

      const userResponse = await fetch(`${ROOT_URL}api/users/me`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      })

      if (!userResponse.ok) throw new Error('Failed to fetch user data')

      const userData = await userResponse.json()
      if (!userData.id) throw new Error('No user ID found in user data')

      const cooldownResponse = await fetch(
        `${ROOT_URL}game/spin-cooldown/${userData.id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (!cooldownResponse.ok) throw new Error('Failed to fetch cooldown time')

      const cooldownData = await cooldownResponse.json()
      setCooldownTime(cooldownData.cooldown_seconds)
    } catch (error) {
      console.error('Error fetching cooldown:', error)
      if (error.message.includes('401')) {
        localStorage.removeItem('authToken')
        window.location.href = '/'
      }
    }
  }, [])

  // Trigger cooldown fetch when `fetchCoolDownTimeState` is true
  useEffect(() => {
    if (fetchCoolDownTimeState) {
      fetchCooldownTime()
    }
  }, [fetchCoolDownTimeState, fetchCooldownTime])

  // Cooldown timer effect
  useEffect(() => {
    console.log('COOL DOWN TIME CALLED:/USE!')
    if (cooldownTime > 0) {
      const timer = setInterval(() => {
        setCooldownTime((prev) => Math.max(prev - 1, 0))
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldownTime, fetchCoolDownTimeState])

  const handleSpinClick = () => {
    if (userId) {
      onSpinIconClick(userId)
    }
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(
      2,
      '0'
    )}`
  }

  const handleFavoritesFilter = async () => {
    const token = decryptToken()
    try {
      const response = await fetch('https://api.ultraspin.vip/game/favorites', {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error('Failed to fetch favorite games')
      }

      const favoriteGamesData = await response.json()
      const favoriteGames = favoriteGamesData.map((game) => game.game_id)

      // Pass the favorite games to the filter function
      onFilter('FAVORITES', favoriteGames)
    } catch (error) {
      console.error('Error fetching favorite games:', error)
    }
  }

  return (
    <div className="lowerbar-container">
      <PrizeWheel userId={userId} onClick={handleSpinClick} />
      <img src={lowerbarImage} alt="Lowerbar" className="lowerbar-image" />
      <div className="icons-container" style={componentStyles.iconsContainer}>
        <div className="icons-group" style={componentStyles.iconsGroup}>
          <button onClick={() => onFilter('ALL')} className="icon-button">
            <div className="icon-wrapper">
              <img
                src={icon1}
                alt="All Games"
                className="nav-icon"
                style={componentStyles.navIcon}
              />
            </div>
            <span className="icon-label" style={componentStyles.iconLabel}>
              All Games
            </span>
          </button>
          <button onClick={() => onFilter('SLOTS')} className="icon-button">
            <div className="icon-wrapper">
              <img
                src={icon2}
                alt="Slots"
                className="nav-icon"
                style={componentStyles.navIcon}
              />
            </div>
            <span className="icon-label" style={componentStyles.iconLabel}>
              Slots
            </span>
          </button>
          <button
            onClick={() => onFilter('FISHING_ARCADE')}
            className="icon-button"
          >
            <div className="icon-wrapper">
              <img
                src={icon3}
                alt="Fishing and Arcade"
                className="nav-icon"
                style={componentStyles.navIcon}
              />
            </div>
            <span className="icon-label" style={componentStyles.iconLabel}>
              Fish
            </span>
          </button>
        </div>

        <div className="timer-display" style={componentStyles.timerDisplay}>
          {formatTime(cooldownTime)}
        </div>

        <div className="icons-group" style={componentStyles.iconsGroup}>
          <button onClick={handleFavoritesFilter} className="icon-button">
            <div className="icon-wrapper">
              <img
                src={icon4}
                alt="Favorites"
                className="nav-icon"
                style={componentStyles.navIcon}
              />
            </div>
            <span className="icon-label" style={componentStyles.iconLabel}>
              Favorites
            </span>
          </button>
          <button
            onClick={() => onFilter('TABLE_BINGO_POKER')}
            className="icon-button"
          >
            <div className="icon-wrapper">
              <img
                src={icon5}
                alt="Table Games"
                className="nav-icon"
                style={componentStyles.navIcon}
              />
            </div>
            <span className="icon-label" style={componentStyles.iconLabel}>
              Other
            </span>
          </button>
          <button onClick={onSearchIconClick} className="icon-button">
            <div className="icon-wrapper">
              <img
                src={icon6}
                alt="Search"
                className="nav-icon"
                style={componentStyles.navIcon}
              />
            </div>
            <span className="icon-label" style={componentStyles.iconLabel}>
              Search
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Lowerbar
